import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AppSpinner from "../components/Spinner/Spinner";
import { FaExchangeAlt } from "react-icons/fa";
import AppModal from "../components/Modal";
import ExchangeProducts from "../components/ExchangeProducts";
import ReturnProducts from "../components/ReturnProducts";
import { getCheckout, getReasons } from "../redux/actions/checkoutActions";
import { configuration } from "../paymentConf";
import { BiTrash } from "react-icons/bi";

const ViewRefund = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const { id } = useParams();
  const [order, setOrder] = React.useState();
  const [refund, setRefund] = React.useState();
  const theAddress = JSON.parse(order?.address ? order?.address : '{"gov":"", "city":"", "city":""}');
  const [show, setShow] = React.useState(false);
  const [showExchange, setShowExchange] = React.useState(false);
  const [sessionData, setSessionData] = React.useState({});
  const isTwo = order?.shoporder === null ? false : true;
  const getOrders = (currentPage) => (dispatch) => {
    setLoading(true);
    axios
      .get(`https://bluepointwear.com/backend/ar/api/return/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setOrder(res.data?.order);
        setRefund(res.data)
      })
      .catch((err) => console.log(err));
  };

  const arrayOfPrices = order?.products?.map((row) => parseInt(row.price));
  const productsCost = arrayOfPrices?.reduce((a, b) => a + b, 0);
  const couponValue = (productsCost * order?.coupon_discount) / 100;

  const getSessionData = (currentPage) => (dispatch) => {
    setLoading(true);
    axios
      .get(`https://bluepointwear.com/backend/ar/api/getSessionData/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setSessionData(res.data);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    if (order?.status === 9) {
      dispatch(getSessionData());
    }
  }, [order, dispatch]);

  React.useEffect(() => {
    dispatch(getOrders());
    dispatch(getCheckout());
    dispatch(getReasons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  React.useState(() => {
    document.title = "Blue Point | طلباتي";
  }, []);

  const handleRePay = () => {
    let data = sessionData.data;
    let price = sessionData.total_price;
    let desc = sessionData.desc;
    let status = "1";
    let session_id = sessionData.session_id;
    let transactionid = sessionData.transactionid;
    let merchent = sessionData.bank_merchant_name;

    if (desc.length > 120) {
      desc = desc.substring(0, 120);
    }

    if (status) {
      configuration(session_id, price, desc, transactionid, merchent);
    } else {
      console.log(data);
    }
  };
  function tConvert(time) {
    // Check correct time format and split into components
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  const deleteRequest = (id) => {
    axios
      .post(
        `https://bluepointwear.com/backend/ar/api/change-order/delete-product`,
        { order_change_product_id: id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        dispatch(getOrders());
      });
  };

  const payRemaining = () => {
    axios
      .get(`https://bluepointwear.com/backend/ar/api/pay-remaining-price/${order?.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let data = res?.data?.data;
        let price = res?.data?.total_price;
        let desc = res?.data?.desc;
        let status = "1";
        let session_id = res?.data?.session_id;
        let transactionid = res?.data?.transactionid;
        let merchent = res?.data?.bank_merchant_name;

        if (desc.length > 120) {
          desc = desc.substring(0, 120);
        }

        if (status) {
          configuration(session_id, price, desc, transactionid, merchent);
        } else {
          console.log(data);
        }
      });
  };

  const reservationDiscount = order?.reservation_discount;
  const couponDeduction = (productsCost * order?.coupon_discount) / 100;
  const priceAfterCoupon = productsCost - couponDeduction;
  const priceAfterReservationDiscount = priceAfterCoupon - reservationDiscount;
  const pendingPrice = order?.pending_price;

  return (
    <div className="container">
      {console.log("ORDER IS", order)}
      {console.log("REFUND IS", refund)}
      <h2 className="text-center bold mb-3">عرض المرتجات لطلب رقم {order?.id}</h2>
      <div>
        <div className="container">
          {loading ? (
            <AppSpinner />
          ) : (
            <div className="order-wrapper">
              <div className="row">
                <div className="col-sm-6">
                  <div className="row">
                    <p className="bold mb-2 col-md-6">
                      <span className="order-data-header">كود المستخدم</span>: user-{order?.user_id}
                    </p>
                    <p className="bold mb-2 col-md-6">
                      <span className="order-data-header">تاريخ المرتجع</span>: {new Date(order?.created_at).toLocaleString("en-GB")}
                    </p>
                  </div>
                  <p className="bold mb-2">
                    <span className="order-data-header">حالة الطلب</span>: {order?.status_text}
                  </p>
                  {order?.shipping_note === null ? null : (
                    <p className="bold mb-2">
                      <span className="order-data-header">الملاحظة الاولى</span>: {order?.shipping_note}
                    </p>
                  )}
                  {/* <p className="bold mb-2">
                    <span className="order-data-header">طريقة التسليم</span>: {order?.shipping?.name}{" "}
                    {order?.shipping?.id !== 2 ? "" : `(${order?.branch?.name})`}
                  </p>
                  <p className="bold mb-2">
                    <span className="order-data-header">طريقة الدفع</span>: {order?.payment?.name}
                  </p> */}
                </div>
                <div className="col-sm-6 mb-4">
                  {order?.address ? (
                    <p className="bold mb-2">
                      <span className="order-data-header">العنوان: </span>
                      {theAddress?.home_number}, شارع {theAddress?.street}, منطقة {theAddress?.area}, مدينة {theAddress?.city}, محافظة {theAddress?.gov},{" "}
                      {theAddress?.near ? "بجوار " + theAddress?.near : null}
                    </p>
                  ) : null}
                  {order?.address ? (
                    <p className="bold mb-2">
                      <span className="order-data-header">وقت التسليم المفضل: </span>
                      من {tConvert(theAddress?.from)} إلى {tConvert(theAddress?.to)}
                    </p>
                  ) : null}
                  {order?.shipping_note_category === null ? null : (
                    <p className="bold mb-2">
                      <span className="order-data-header">الملاحظة الثانية</span>: {order?.shipping_note_category}
                    </p>
                  )}
                  {order?.note === null ? null : (
                    <p className="bold mb-2">
                      <span className="order-data-header">الملاحظة الثالثة</span>: {order?.note}
                    </p>
                  )}
                </div>

                <h5 className="text-center bold table-header">المنتجات</h5>
                <div style={{ maxWidth: "100vw", overflowX: "scroll" }}>
                  <table className="table order-table table-borderless table-hover table-responsive mt-5">
                    <thead>
                      <tr>
                        <th scope="col">المنتج</th>
                        <th scope="col">عدد القطع</th>
                        <th scope="col">سعر القطعة</th>
                        <th scope="col">قيمة المرتجع</th>
                      </tr>
                    </thead>

                    <tbody>
                      {refund?.products?.map((product) => {
                        return (
                          <tr key={product.id}>
                            <td>{product?.item?.main?.title}</td>
                            <td>{product?.quantity}</td>
                            <td>{product?.price_per_piece} جنيه</td>
                            <td>{product?.price * product?.quantity} جنيه</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {order?.reservation_discount === 0 ? (
                  <div className="money-div flex-between mt-5">
                    {/* <div>
                      <p className="mb-2 bold">
                        <span className="order-data-header">الإجمالي:</span> {isTwo ? productsCost + order?.shoporder?.total_price : productsCost} جنيه
                      </p>
                      {order?.coupon_discount > 0 ? (
                        <p className="mb-2 bold">
                          <span className="order-data-header">الكوبون: </span>
                          {couponValue} جنيه ({order?.coupon_code})
                        </p>
                      ) : null}
                      <p className="mb-2 bold">
                        <span className="order-data-header">مصاريف الشحن:</span> {order?.shipping?.price} جنيه
                      </p>
                      <p className="mb-2 bold">
                        <span className="order-data-header">المطلوب سداده:</span>{" "}
                        {isTwo ? order?.total_price + order?.shoporder?.total_price : order?.total_price} جنيه
                      </p>
                      {order?.pending_price > 0 && (
                        <p className="mb-2 bold">
                          <span className="order-data-header">المبلغ المتبقي:</span> {order?.pending_price} جنيه
                        </p>
                      )}
                    </div> */}
                    {loading ? null : (
                      <div className="exchange-return-wrapper">
                        {pendingPrice ? (
                          <button className="login-button exchange-button mt-2" onClick={() => payRemaining()}>
                            ادفع المبلغ المتبقي
                          </button>
                        ) : null}
                        {order?.replace_available === 1 ? null : order?.changes?.length < 1 ? (
                          <button onClick={() => setShowExchange(!showExchange)} className="login-button exchange-button">
                            الاستبدال والاسترجاع <FaExchangeAlt />
                          </button>
                        ) : null}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="fourth-step-wrapper mt-2">
                    {/* <div className="cost-top-wrappe">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 cost-top-content">
                          <span className="cost-top-content"> قيمة الطلب: {productsCost} جنيه</span>
                        </div>
                        {Boolean(order?.coupon_discount) && (
                          <div className="col-md-6 col-sm-12 cost-top-content">
                            <span className="cost-top-content">
                              {" "}
                              خصم الكوبون: {couponDeduction} جنيه ({order?.coupon_discount.toFixed(2)}%)
                            </span>
                          </div>
                        )}
                        {Boolean(reservationDiscount) && (
                          <div className="col-md-6 col-sm-12 cost-top-content">
                            <span className="cost-top-content">خصم الدفعة المقدمة: {order?.reservation_discount.toFixed(2)} جنيه</span>
                          </div>
                        )}
                        <div className="col-md-6 col-sm-12 cost-top-content">
                          <span className="cost-t\=op-content">صافي قيمة الطلب: {priceAfterReservationDiscount?.toFixed(2)} جنيه</span>
                        </div>
                        {Boolean(reservationDiscount) && (
                          <div className="col-md-6 col-sm-12 cost-top-content">
                            <span className="cost-top-content">المبلغ المدفوع: {order?.paid_price.toFixed(2)} جنيه</span>
                          </div>
                        )}
                        {Boolean(order?.reservation_discount) && (
                          <div className="col-md-6 col-sm-12 cost-top-content">
                            <span className="cost-top-content">المبلغ المتبقي: {order?.pending_price.toFixed(2)} جنيه</span>
                          </div>
                        )}
                        <div className="col-md-6 col-sm-12 cost-top-content">
                          <span className="cost-top-content">رسوم توصيل: {order?.shipping_price.toFixed(2)} جنيه</span>
                        </div>
                        <div className="col-md-6 col-sm-12 cost-top-content">
                          <span className="cost-t\=op-content">
                            الإجمالي بعد رسوم التوصيل: {(priceAfterReservationDiscount + order?.shipping_price)?.toFixed(2)} جنيه
                          </span>
                        </div>
                      </div>
                    </div> */}
                    <div className="flex-center">
                      <hr />
                    </div>
                    <div>
                      <div className="cost-top-wrapper">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <h5 className=" bold">المبلغ المطلوب سداده: {order?.pending_price?.toFixed(2)} جنيه</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    {order?.pending_price ? (
                      <button className="login-button exchange-button mt-2" onClick={() => payRemaining()}>
                        ادفع المبلغ المتبقي
                      </button>
                    ) : null}
                    <br />
                    {order?.replace_available === 1 ? null : order?.changes?.length < 1 ? (
                      <button onClick={() => setShowExchange(!showExchange)} className="login-button exchange-button">
                        الاستبدال والاسترجاع <FaExchangeAlt />
                      </button>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewRefund;
