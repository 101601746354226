import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AppSpinner from "../components/Spinner/Spinner";
import { getNewById } from "../redux/actions/newsActions";
import parse from "html-react-parser";
import { setLoading } from "../redux/actions/loading";

const ViewNew = () => {
  const dispatch = useDispatch();
  const { newId } = useParams();
  React.useEffect(() => {
    dispatch(setLoading());
    dispatch(getNewById(newId));
  }, [dispatch, newId]);
  const theNew = useSelector((state) => state.news.new?.blog);
  return theNew ? (
    <div className="container">
      <h2 className="bold mb-4 mt-4">{theNew?.title}</h2>
      {theNew?.description?.length > 3 ? parse(theNew?.description) : null}
    </div>
  ) : (
    <AppSpinner />
  );
};

export default ViewNew;
