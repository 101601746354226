import React from "react";
import whatsApp from "../assets/WhatsApp.svg";
import youtube from "../assets/Youtube.svg";
import { useDispatch, useSelector } from "react-redux";
import { getTerms } from "../redux/actions/termsActions";
import visa from "../assets/visa.png";
import master from "../assets/master.png";
import arab from "../assets/arab.png";
import AppModal from "./Modal";
import SendEmail from "./SendEmail";

const AppFooter = ({ auth }) => {
  const dispatch = useDispatch();
  const socialMedia = useSelector((state) => state?.terms?.terms?.setting);
  const emails = useSelector((state) => state?.terms?.terms?.emails);

  const [show, setShow] = React.useState();
  React.useEffect(() => {
    dispatch(getTerms());
  }, [dispatch]);
  return (
    <div className="follow-us-wrapperREMOVE">
      <div className="footer-data">
        <AppModal show={show} setShow={setShow} content={<SendEmail />} modalTitle="إرسال رسالة" />
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-12 footer-links">
              <div className="footer-logo-wrapper">
                <img style={{ maxWidth: "100px" }} src="./logo.jpg" alt="img" />
              </div>
              <div className="flex-between footer-icons-wrapper">
                <a href={socialMedia?.youtube}>
                  <img alt="Youtube" src={youtube} className="social-media-icon " />
                </a>
                <a href="https://wa.me/+201550773142">
                  <img alt="Whatsapp" src={whatsApp} className="social-media-icon mr-5" />
                </a>
              </div>
            </div>
            <div className="col-md-2" />
            <div className="col-md-4 col-sm-12 footer-links">
              <h2>تواصل معنا</h2>
              <div className="flex-between">
                <div>
                  {emails?.map((email, index) => {
                    return (
                      <p onClick={() => setShow(false)} key={index}>
                        البريد : {email}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-center">
          <img alt="img" className="visa" src={visa} />
          <img alt="img" className="visa" src={master} />
          <img alt="img" className="arab" src={arab} />
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
