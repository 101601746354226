import axios from "axios";
import React from "react";
import { AiFillEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AppPagination from "../components/Pagination";
import AppSpinner from "../components/Spinner/Spinner";
import noOrders from "../assets/no-orders.png";
import { Link } from "react-router-dom";

const Refunds = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [orders, setOrders] = React.useState();
  const [lastPage, setLastPage] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [paginated, setPaginated] = React.useState();
  const user = useSelector((state) => state.users.user);

  const getOrders = (currentPage) => (dispatch) => {
    setLoading(true);
    axios
      .get(`https://bluepointwear.com/backend/ar/api/return/orders`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setOrders(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  React.useEffect(() => {
    dispatch(getOrders(currentPage));
  }, [currentPage, dispatch]);
  React.useState(() => {
    document.title = "Blue Point | طلباتي";
  }, []);

  return (
    <div className="container">
      <h2 className="bold text-center mb-4 mt-4"> {user?.establishment === "e14" ? "المرتجعات" : "طلباتي"}</h2>
      {loading ? (
        <AppSpinner />
      ) : orders?.length === 0 ? (
        <div>
          <h4 className="text-center">
            لا توجد طلبات, للتسوق
            <span onClick={() => history.push("/")} className="blue pointer">
              {" "}
              اضغط هنا
            </span>
          </h4>
          <div className="flex-center">
            <img alt="broken-heart" className="broken-heart" src={noOrders} />
          </div>
        </div>
      ) : (
        <>
          <table className="table table-borderless table-hover mt-5 mb-5">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">حالة الطلب</th>
                <th scope="col">تاريخ الطلب</th>
                <th scope="col">السعر الإجمالي</th>
                <th scope="col">عرض الطلب</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((order, index) => {
                return (
                  <tr key={order.order.id}>
                    <th scope="row">{order.order.id}</th>
                    <td>{order?.order.AiFillEyestatus_text}</td>
                    <td>{new Date(order?.created_at).toLocaleDateString("en-GB")}</td>
                    <td>{order?.order.total_price} جنيه</td>
                    <td>
                      {console.log("REFUYND ID IS",order.id)}
                      <button onClick={() => history.push(`/view-refund/${order.id}`)} className="show-order-icon pointer">
                        عرض المرتجعات
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="mb-3 mt-3">
            <AppPagination paginated={paginated} setPaginated={setPaginated} pagesNum={lastPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </div>
        </>
      )}
    </div>
  );
};

export default Refunds;
