export function configuration(session_id, price, desc, transactionid) {
  window.Checkout.configure({
    session: {
      id: `${session_id}`,
    },
    interaction: {
      merchant: {
        name: "Merchant.1203514J8NL3",
        email: "test@test.com",
        logo: "https://bluepointwear.com/backend/uploads/images/thumbnail/logo.png",
      },
      locale: "en_US",
      theme: "default",
      country: "EGY",
      displayControl: {
        billingAddress: "HIDE",
        customerEmail: "HIDE",
        orderSummary: "HIDE",
        paymentConfirmation: "SHOW",
        shipping: "HIDE",
        paymentTerms: "SHOW_IF_SUPPORTED",
      },
    },

    transaction: {
      reference: `${transactionid}`,
    },
    order: {
      amount: `${price}`,
      description: `${desc}`,
    },

    userId: "1",
  });

  window.Checkout.showPaymentPage(); //showLightbox();
}
