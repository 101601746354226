/* eslint-disable no-mixed-operators */
import React from "react";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { deleteFromCart, getCart, setQuantityLoading, updateCart } from "../redux/actions/cartsActions";
import { setLoading } from "../redux/actions/productsActions";
import AppSpinner from "./Spinner/Spinner";
import ConfirmationModal from "./ConfirmationModal";
import { BiLoaderAlt } from "react-icons/bi";
import noItems from "../assets/emptycart.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { myError } from "./Toast";

const CartContent = ({ setTotalCost, setCartLength, checkLength }) => {
  const dispatch = useDispatch();
  const [mobile, setMobile] = React.useState();
  const cartItems = useSelector((state) => state.carts.carts?.cart);
  const loading = useSelector((state) => state.carts.loading);
  const updated = useSelector((state) => state.carts.updated);
  const quantityUpdated = useSelector((state) => state.carts.quantityUpdated);
  const [show, setShow] = React.useState(false);
  const [quantity, setQuantity] = React.useState(cartItems?.map((row, index) => row.quantity));
  const quantityLoading = useSelector((state) => state.carts.quantityLoading);
  const [newQuantity, setNewQuantity] = React.useState();
  const [theIndex, setTheIndex] = React.useState();
  const history = useHistory();
  const [id, setId] = React.useState("");
  React.useEffect(() => {
    setQuantity(cartItems?.map((row, index) => row.quantity));
  }, [cartItems]);
  React.useEffect(() => {
    dispatch(setLoading());
    dispatch(getCart());
  }, [updated, dispatch]);

  React.useEffect(() => {
    dispatch(getCart());
    setTheIndex(500);
  }, [quantityUpdated, dispatch]);

  React.useState(() => {
    if (window.innerWidth < 995) {
      setMobile(true);
    }
  }, []);

  const removeItem = (id) => {
    setId(id);
    setShow(true);
  };

  const arrayOfPrices = cartItems?.map((row) => row.price);

  React.useEffect(() => {
    setTotalCost(arrayOfPrices?.reduce((a, b) => a + b, 0));
  }, [arrayOfPrices, setTotalCost]);

  React.useEffect(() => {
    if (checkLength) {
      setCartLength(cartItems?.length);
    }
  }, [cartItems, setCartLength, checkLength]);

  React.useEffect(() => {
    dispatch(setQuantityLoading());
    dispatch(updateCart(newQuantity, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newQuantity, dispatch]);

  return loading ? (
    <div className="flex-center">
      <AppSpinner />
    </div>
  ) : cartItems?.length === 0 ? (
    <div>
      <h4 className="text-center">
        السلة فارغة, للتسوق
        <span onClick={() => history.push("/")} className="blue pointer">
          {" "}
          اضغط هنا
        </span>
      </h4>
      <div className="flex-center">
        <img alt="img" src={noItems} />
      </div>
    </div>
  ) : (
    <div className="cart-content flex-center mt-4">
      <table className={`table table-borderless ${mobile ? null : "table-hover"} cart-table table-responsive`}>
        <ConfirmationModal
          content="إزالة المنتج من السلة"
          show={show}
          setShow={setShow}
          theAction={deleteFromCart}
          id={id}
        />
        <thead>
          <tr>
            <th scope="col">المنتج</th>
            <th scope="col">الكمية</th>
            <th scope="col">السعر</th>
            <th scope="col">حذف</th>
          </tr>
        </thead>
        <tbody>
          {cartItems?.map((row, index) => {
            return (
              <tr key={index} title={row.title}>
                <th scope="row">
                  <div className="cart-image-title">
                    <img
                      style={{ objectFit: "cover" }}
                      alt="shirt"
                      onClick={() => history.push(`view-product/${row?.item?.product_id}`)}
                      src={row.photo}
                      className="cart-table-image pointer"
                    />
                    <div>
                      <h3
                        className="cart-product-title pointer"
                        onClick={() => history.push(`/view-product/${row?.item?.product_id}`)}
                      >
                        {row.title.length > 20 ? `${row.title.substring(0, 20)}....` : row.title}
                      </h3>
                      <h6 className="cart-category-title">{row.category_parent_name}</h6>
                      <div className="mt-0 cart-size mb-0">
                        <span>{row?.item?.size?.name}</span>
                        <span> | </span>
                        <span>{row?.item?.color?.name}</span>
                      </div>
                    </div>
                  </div>
                </th>
                <td className="flex-right">
                  {mobile ? (
                    <div
                      style={{ zoom: "80%", display: "flex", flexDirection: "column", width: "50px" }}
                      className="quantity-counter"
                    >
                      <span
                        style={{ width: "40px", height: "40px" }}
                        onClick={() => {
                          if (quantity[index] !== row?.item?.quantity) {
                            setNewQuantity(quantity[index] + 1);
                            setId(row.item_id);
                            setTheIndex(index);
                          } else {
                            myError(`الكمية المتوقرة من المنتج ${row.item?.quantity} فقط`);
                          }
                        }}
                        className="plus"
                      >
                        {" "}
                        +{" "}
                      </span>

                      <span style={{ width: "40px", height: "40px" }}>
                        {(quantityLoading === true && index === theIndex) || loading === true ? (
                          <BiLoaderAlt size={12} className="login-loader" />
                        ) : quantity ? (
                          quantity[index]
                        ) : null}
                      </span>
                      <span
                        style={{ width: "40px", height: "40px" }}
                        onClick={() => {
                          if (quantity[index] !== 1) {
                            setNewQuantity(quantity[index] - 1);
                            setId(row.item_id);
                            setTheIndex(index);
                          }
                        }}
                        className="minus"
                      >
                        {" "}
                        -{" "}
                      </span>
                    </div>
                  ) : (
                    <div className="quantity-counter">
                      <span
                        onClick={() => {
                          if (quantity[index] !== 1) {
                            setNewQuantity(quantity[index] - 1);
                            setId(row.item_id);
                            setTheIndex(index);
                          }
                        }}
                        className="minus"
                      >
                        {" "}
                        -{" "}
                      </span>
                      <span>
                        {(quantityLoading === true && index === theIndex) || loading === true ? (
                          <BiLoaderAlt size={12} className="login-loader" />
                        ) : quantity ? (
                          quantity[index]
                        ) : null}
                      </span>
                      <span
                        onClick={() => {
                          if (quantity[index] !== row?.item?.quantity) {
                            setNewQuantity(quantity[index] + 1);
                            setId(row.item_id);
                            setTheIndex(index);
                          } else {
                            myError(`الكمية المتوقرة من المنتج ${row.item?.quantity} فقط`);
                          }
                        }}
                        className="plus"
                      >
                        {" "}
                        +{" "}
                      </span>
                    </div>
                  )}
                </td>
                <td>
                  <div className="price-cart">
                    {row.price} جنيه{" "}
                    {parseInt(row?.item?.price) < row?.item?.old_price ? (
                      <span className="old-price-cart">{row?.item?.old_price * row?.quantity} جنيه</span>
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="price-cart">
                    <MdDelete onClick={() => removeItem(row.id)} className="delete-icon" />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CartContent;
