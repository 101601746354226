import { GET_ORDER_BY_ID, LOADING, CARTS_LOADING, GET_CARTS, ADD_AND_RETURN, ADD_AND_COINTINUE, DELETE_PRODUCT, UPDATE_QUANTITY, QUANTITY_LOADING } from '../actions/types'

const initialState = {
    carts: [],
    order: [],
    updated: [],
    loading: true,
    return: false,
    continue: false,
    quantityUpdated: [],
    quantityLoading: true,
    quantity: null
}

const cartsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CARTS:
            return {
                ...state,
                carts: action.payload,
                loading: false,
                quantityLoading: false
            };


        case UPDATE_QUANTITY:
            return {
                ...state,
                quantityUpdated: Math.random(),
                quantity:action.payload ,
                loading: false,
            };


        case QUANTITY_LOADING:
            return {
                ...state,
                quantityLoading: true
            };
        case GET_ORDER_BY_ID:
            return {
                ...state,
                order: action.payload?.data?.order,
                loading: false
            };
        case ADD_AND_RETURN:
            return {
                ...state,
                return: true
            };
        case ADD_AND_COINTINUE:
            return {
                ...state,
                continue: true
            };
        case DELETE_PRODUCT:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case CARTS_LOADING:
            return {
                loading: true
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default cartsReducer;