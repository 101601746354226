import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AppSpinner from "../components/Spinner/Spinner";
import { FaExchangeAlt } from "react-icons/fa";
import AppModal from "../components/Modal";
import ExchangeProducts from "../components/ExchangeProducts";
import ReturnProducts from "../components/ReturnProducts";
import { getCheckout, getReasons } from "../redux/actions/checkoutActions";
import { configuration } from "../paymentConf";
import { BiTrash } from "react-icons/bi";

const ViewOrder = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const { orderId } = useParams();
  const [order, setOrder] = React.useState();
  const theAddress = JSON.parse(order?.address ? order?.address : '{"gov":"", "city":"", "city":""}');
  const [show, setShow] = React.useState(false);
  const [showExchange, setShowExchange] = React.useState(false);
  const [sessionData, setSessionData] = React.useState({});
  const isTwo = order?.shoporder === null ? false : true;
  const getOrders = (currentPage) => (dispatch) => {
    setLoading(true);
    axios
      .get(`https://bluepointwear.com/backend/ar/api/order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setOrder(res.data?.order);
      })
      .catch((err) => console.log(err));
  };

  const arrayOfPrices = order?.products?.map((row) => parseInt(row.price));
  const productsCost = arrayOfPrices?.reduce((a, b) => a + b, 0);
  const couponValue = (productsCost * order?.coupon_discount) / 100;

  const getSessionData = (currentPage) => (dispatch) => {
    setLoading(true);
    axios
      .get(`https://bluepointwear.com/backend/ar/api/getSessionData/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setSessionData(res.data);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    if (order?.status === 9) {
      dispatch(getSessionData());
    }
  }, [order, dispatch]);

  React.useEffect(() => {
    dispatch(getOrders());
    dispatch(getCheckout());
    dispatch(getReasons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  React.useState(() => {
    document.title = "Blue Point | طلباتي";
  }, []);

  const handleRePay = () => {
    let data = sessionData.data;
    let price = sessionData.total_price;
    let desc = sessionData.desc;
    let status = "1";
    let session_id = sessionData.session_id;
    let transactionid = sessionData.transactionid;
    let merchent = sessionData.bank_merchant_name;

    if (desc.length > 120) {
      desc = desc.substring(0, 120);
    }

    if (status) {
      configuration(session_id, price, desc, transactionid, merchent);
    } else {
      console.log(data);
    }
  };
  function tConvert(time) {
    // Check correct time format and split into components
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  const ExchangeButton = () => {
    return order?.changes[0]?.modify ? (
      <button onClick={() => setShowExchange(!showExchange)} className="login-button exchange-button">
        تعديل الإستبدال <FaExchangeAlt />
      </button>
    ) : null;
  };

  const deleteRequest = (id) => {
    axios
      .post(
        `https://bluepointwear.com/backend/ar/api/change-order/delete-product`,
        { order_change_product_id: id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        dispatch(getOrders());
      });
  };

  const payRemaining = () => {
    axios
      .get(`https://bluepointwear.com/backend/ar/api/pay-remaining-price/${order?.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let data = res?.data?.data;
        let price = res?.data?.total_price;
        let desc = res?.data?.desc;
        let status = "1";
        let session_id = res?.data?.session_id;
        let transactionid = res?.data?.transactionid;
        let merchent = res?.data?.bank_merchant_name;

        if (desc.length > 120) {
          desc = desc.substring(0, 120);
        }

        if (status) {
          configuration(session_id, price, desc, transactionid, merchent);
        } else {
          console.log(data);
        }
      });
  };

  const newShippingPrice = order?.changes[0]?.shipping_price;
  const reservationDiscount = order?.reservation_discount;
  const couponDeduction = (productsCost * order?.coupon_discount) / 100;
  const priceAfterCoupon = productsCost - couponDeduction;
  const priceAfterReservationDiscount = priceAfterCoupon - reservationDiscount;
  const pendingPrice = order?.pending_price;

  return (
    <div className="container">
      <AppModal
        show={show}
        setShow={setShow}
        content={<ReturnProducts setShow={setShow} orderId={order?.id} products={order?.products} />}
        modalTitle="طلب الاستبدال"
      />
      <h2 className="text-center bold mb-3">عرض الطلب رقم {orderId}</h2>
      <div>
        <div className="container">
          {loading ? (
            <AppSpinner />
          ) : (
            <div className="order-wrapper">
              <div className="row">
                <div className="col-sm-6">
                  <div className="row">
                    <p className="bold mb-2 col-md-6">
                      <span className="order-data-header">كود المستخدم</span>: user-{order?.user_id}
                    </p>
                    <p className="bold mb-2 col-md-6">
                      <span className="order-data-header">تاريخ الطلب</span>:{" "}
                      {new Date(order?.created_at).toLocaleString("en-GB")}
                    </p>
                  </div>
                  <p className="bold mb-2">
                    <span className="order-data-header">حالة الطلب</span>: {order?.status_text}
                  </p>
                  {order?.shipping_note === null ? null : (
                    <p className="bold mb-2">
                      <span className="order-data-header">الملاحظة الاولى</span>: {order?.shipping_note}
                    </p>
                  )}
                  <p className="bold mb-2">
                    <span className="order-data-header">طريقة التسليم</span>: {order?.shipping?.name}{" "}
                    {order?.shipping?.id !== 2 ? "" : `(${order?.branch?.name})`}
                  </p>
                  <p className="bold mb-2">
                    <span className="order-data-header">طريقة الدفع</span>: {order?.payment?.name}
                  </p>
                </div>
                <div className="col-sm-6 mb-4">
                  {order?.address ? (
                    <p className="bold mb-2">
                      <span className="order-data-header">العنوان: </span>
                      {theAddress?.home_number}, شارع {theAddress?.street}, منطقة {theAddress?.area}, مدينة{" "}
                      {theAddress?.city}, محافظة {theAddress?.gov},{" "}
                      {theAddress?.near ? "بجوار " + theAddress?.near : null}
                    </p>
                  ) : null}
                  {order?.address ? (
                    <p className="bold mb-2">
                      <span className="order-data-header">وقت التسليم المفضل: </span>
                      من {tConvert(theAddress?.from)} إلى {tConvert(theAddress?.to)}
                    </p>
                  ) : null}

                  {order?.shipping_id === 4 ? null : order?.branch_id ? (
                    <p className="bold mb-2">
                      <span className="order-data-header">اسم شركة الشحن: </span>
                      {order?.branch?.name}
                      <br />
                      <span className="order-data-header">رقم شركة الشحن: </span>
                      {order?.branch?.phone}
                    </p>
                  ) : null}
                  {order?.shipping_note_category === null ? null : (
                    <p className="bold mb-2">
                      <span className="order-data-header">الملاحظة الثانية</span>: {order?.shipping_note_category}
                    </p>
                  )}
                  {order?.note === null ? null : (
                    <p className="bold mb-2">
                      <span className="order-data-header">الملاحظة الثالثة</span>: {order?.note}
                    </p>
                  )}
                </div>

                <h5 className="text-center bold table-header">المنتجات</h5>
                <div style={{ maxWidth: "100vw", overflowX: "scroll" }}>
                  <table className="table order-table table-borderless table-hover table-responsive mt-5">
                    <thead>
                      <tr>
                        <th scope="col">المنتج</th>
                        <th scope="col">اللون</th>
                        <th scope="col">المقاس</th>
                        <th scope="col">سعر القطعة</th>
                        <th scope="col">المطلوب / المتبقي</th>
                        <th scope="col">إجمالي المنصرف</th>
                        <th scope="col">قيمة المطلوب / المتبقي</th>
                        <th scope="col"> قيمة المنصرف</th>
                        <th scope="col">قيمة الطلب</th>
                      </tr>
                    </thead>

                    <tbody>
                      {!isTwo
                        ? order?.products?.map((product) => {
                            return (
                              <tr key={product.id}>
                                <td>{product?.product?.title}</td>
                                <td>{product?.color?.name}</td>
                                <td>{product?.size?.name}</td>
                                <td>{product?.price_pice} جنيه</td>
                                <td>{product?.quantity}</td>
                                <td>{product?.sold_quantity}</td>
                                <td>{parseInt(product?.quantity) * parseInt(product?.price_pice)} جنيه</td>
                                <td>{parseInt(product?.sold_quantity) * parseInt(product?.price_pice)} جنيه</td>
                                <td>{product?.price} جنيه</td>
                              </tr>
                            );
                          })
                        : order?.products?.concat?.(order?.shoporder?.order_products)?.map((product) => {
                            return (
                              <tr key={product.id}>
                                <td style={{ maxWidth: "180px" }}>{product?.title}</td>
                                <td>{product?.color?.name}</td>
                                <td>{product?.size?.name}</td>
                                <td>{product?.price_pice} جنيه</td>
                                <td>{product?.quantity}</td>
                                <td>{product?.sold_quantity}</td>
                                <td>{parseInt(product?.quantity) * parseInt(product?.price_pice)} جنيه</td>
                                <td>{parseInt(product?.sold_quantity) * parseInt(product?.price_pice)} جنيه</td>
                                <td>{product?.price} جنيه</td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>

                {order?.reservation_discount === 0 ? (
                  <div className="money-div flex-between mt-5">
                    <div>
                      <p className="mb-2 bold">
                        <span className="order-data-header">الإجمالي:</span>{" "}
                        {isTwo ? productsCost + order?.shoporder?.total_price : productsCost} جنيه
                      </p>
                      {order?.coupon_discount > 0 ? (
                        <p className="mb-2 bold">
                          <span className="order-data-header">الكوبون: </span>
                          {couponValue} جنيه ({order?.coupon_code})
                        </p>
                      ) : null}
                      <p className="mb-2 bold">
                        <span className="order-data-header">مصاريف الشحن:</span> {order?.shipping?.price} جنيه
                      </p>
                      <p className="mb-2 bold">
                        <span className="order-data-header">المطلوب سداده:</span>{" "}
                        {isTwo ? order?.total_price + order?.shoporder?.total_price : order?.total_price} جنيه
                      </p>
                      {order?.pending_price > 0 && (
                        <p className="mb-2 bold">
                          <span className="order-data-header">المبلغ المتبقي:</span> {order?.pending_price} جنيه
                        </p>
                      )}
                    </div>
                    {loading ? null : (
                      <div className="exchange-return-wrapper">
                        {pendingPrice ? (
                          <button className="login-button exchange-button mt-2" onClick={() => payRemaining()}>
                            ادفع المبلغ المتبقي
                          </button>
                        ) : null}
                        {order?.replace_available === 1 ? null : order?.changes?.length < 1 ? (
                          <button
                            onClick={() => setShowExchange(!showExchange)}
                            className="login-button exchange-button"
                          >
                            الاستبدال والاسترجاع <FaExchangeAlt />
                          </button>
                        ) : null}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="fourth-step-wrapper mt-2">
                    <div className="cost-top-wrappe">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 cost-top-content">
                          <span className="cost-top-content"> قيمة الطلب: {productsCost} جنيه</span>
                        </div>
                        {Boolean(order?.coupon_discount) && (
                          <div className="col-md-6 col-sm-12 cost-top-content">
                            <span className="cost-top-content">
                              {" "}
                              خصم الكوبون: {couponDeduction} جنيه ({order?.coupon_discount.toFixed(2)}%)
                            </span>
                          </div>
                        )}
                        {Boolean(reservationDiscount) && (
                          <div className="col-md-6 col-sm-12 cost-top-content">
                            <span className="cost-top-content">
                              خصم الدفعة المقدمة: {order?.reservation_discount.toFixed(2)} جنيه
                            </span>
                          </div>
                        )}
                        <div className="col-md-6 col-sm-12 cost-top-content">
                          <span className="cost-t\=op-content">
                            صافي قيمة الطلب: {priceAfterReservationDiscount?.toFixed(2)} جنيه
                          </span>
                        </div>
                        {Boolean(reservationDiscount) && (
                          <div className="col-md-6 col-sm-12 cost-top-content">
                            <span className="cost-top-content">
                              المبلغ المدفوع: {order?.paid_price.toFixed(2)} جنيه
                            </span>
                          </div>
                        )}
                        {Boolean(order?.reservation_discount) && (
                          <div className="col-md-6 col-sm-12 cost-top-content">
                            <span className="cost-top-content">
                              المبلغ المتبقي: {order?.pending_price.toFixed(2)} جنيه
                            </span>
                          </div>
                        )}
                        <div className="col-md-6 col-sm-12 cost-top-content">
                          <span className="cost-top-content">رسوم توصيل: {order?.shipping_price.toFixed(2)} جنيه</span>
                        </div>
                        <div className="col-md-6 col-sm-12 cost-top-content">
                          <span className="cost-t\=op-content">
                            الإجمالي بعد رسوم التوصيل:{" "}
                            {(priceAfterReservationDiscount + order?.shipping_price)?.toFixed(2)} جنيه
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex-center">
                      <hr />
                    </div>
                    <div>
                      <div className="cost-top-wrapper">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <h5 className=" bold">المبلغ المطلوب سداده: {order?.pending_price?.toFixed(2)} جنيه</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    {order?.pending_price ? (
                      <button className="login-button exchange-button mt-2" onClick={() => payRemaining()}>
                        ادفع المبلغ المتبقي
                      </button>
                    ) : null}
                    <br />
                    {order?.replace_available === 1 ? null : order?.changes?.length < 1 ? (
                      <button onClick={() => setShowExchange(!showExchange)} className="login-button exchange-button">
                        الاستبدال والاسترجاع <FaExchangeAlt />
                      </button>
                    ) : null}
                  </div>
                )}

                {order?.changes?.length > 0 ? (
                  <div>
                    <hr />
                    <h2 className="text-center bold table-header" style={{ marginBottom: "40px" }}>
                      رقم الإستبدال: {order?.changes[0]?.id} بتاريخ{" "}
                      {`(${new Date(order?.changes[0]?.updated_at).toLocaleDateString("en-GB")})`}
                    </h2>
                    <div style={{ marginTop: "30px" }}>
                      <h5 className="text-center bold table-header">المنتجات الجديدة</h5>
                      <table className="table-responsive table table-borderless table-hover mt-5 mb-5">
                        <thead>
                          <tr>
                            <th scope="col">المنتج</th>
                            <th scope="col">اللون</th>
                            <th scope="col">المقاس</th>
                            <th scope="col">الكمية</th>
                            <th scope="col">سعر الوحدة</th>
                            <th scope="col">الإجمالي</th>
                            <th scope="col">حذف</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order?.changes[0]?.products?.map((product) => {
                            return (
                              <tr key={product.id}>
                                <td>{product?.replace?.main?.title}</td>
                                <td>{product?.replace?.color?.name}</td>
                                <td>{product?.replace?.size?.name}</td>
                                <td>{product?.replace_quantity}</td>
                                <td>{product?.replace_price / product?.replace_quantity} جنيه</td>
                                <td>{product?.replace_price} جنيه</td>
                                <td>
                                  <BiTrash
                                    color="#f00"
                                    size="20"
                                    className="pointer"
                                    onClick={() => deleteRequest(product?.id)}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <hr />
                    <div>
                      <h5 className="text-center bold table-header">المنتجات المرتجعة</h5>
                      <table className="table table-responsive table-borderless table-hover mt-5 mb-5">
                        <thead>
                          <tr>
                            <th scope="col">المنتج</th>
                            <th scope="col">اللون</th>
                            <th scope="col">المقاس</th>
                            <th scope="col">الكمية</th>
                            <th scope="col">سعر الوحدة</th>
                            <th scope="col">الإجمالي</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order?.changes[0]?.products?.map((product) => {
                            return (
                              <tr key={product.id}>
                                <td>{product?.return?.main?.title}</td>
                                <td>{product?.return?.color?.name}</td>
                                <td>{product?.return?.size?.name}</td>
                                <td>{product?.return_quantity}</td>
                                <td>{product?.return_price / product?.return_quantity} جنيه</td>
                                <td>{product?.return_price} جنيه</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <p className="bold mb-2 col-md-6">
                        <span className="order-data-header">قيمة المرتجع</span>: {order?.changes[0]?.old_item_amount}{" "}
                        جنيه
                      </p>
                      <p className="bold mb-2 col-md-6">
                        <span className="order-data-header">قيمة المنتجات الجديدة</span>:{" "}
                        {order?.changes[0]?.new_item_amount - parseInt(order?.changes[0]?.shipping_price)} جنيه
                      </p>
                      <p className="bold mb-2 col-md-6">
                        <span className="order-data-header">طريقة التوصيل</span>:
                        <span> {order?.changes[0]?.shipping_name}</span>
                      </p>
                      <p className="bold mb-2 col-md-6">
                        <span className="order-data-header">سعر التوصيل</span>:<span> {newShippingPrice}</span>
                      </p>
                      <p className="bold mb-2 col-md-6">
                        <span className="order-data-header">الفرق</span>:
                        <span style={order?.changes[0]?.total > 0 ? { color: "green" } : { color: "red" }}>
                          {" "}
                          {order?.changes[0]?.total} جنيه
                        </span>
                      </p>

                      <div className="flex-between">
                        <div />
                        <div style={{ maxWidth: "200px" }}>
                          <ExchangeButton />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
        <div className={showExchange ? "show-exchange" : "hide-exchange"}>
          <ExchangeProducts
            ExchangeButton={ExchangeButton}
            getOrder={getOrders}
            order={order}
            paymentId={order?.payment?.id}
            setShowDown={setShowExchange}
            orderId={order?.id}
            products={order?.products}
          />
        </div>

        {order?.changes?.length > 0 ? (
          <div className="flex-between">
            <div></div>
            <h5 style={{ color: "red", fontWeight: "500" }}>
              برجاء مراجعة مواعيد و مكان الاستبدال على صفحة الاخبار في الموقع و على جروب الزي
            </h5>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ViewOrder;
