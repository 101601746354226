import React from "react";
import { useSelector } from "react-redux";

const FourthStep = ({ totalCost, code, shippingFees, setTotalPrice, codeType, reservation, reservationId }) => {
  const discountPrice = codeType === "2" ? (totalCost * (100 - code)) / 100 : totalCost - code;
  const reservations = useSelector((state) => state.checkout?.checkout?.reservation);
  const reservationMethod = reservations?.find((item) => item?.id == reservationId);
  const reservationDiscount = reservationMethod?.discount;
  const upfront = reservationMethod?.payment_amount;
  const totalCostAfterReservation = Boolean(reservationDiscount)
    ? (totalCost * (100 - reservationDiscount)) / 100
    : totalCost;
  const priceAfterPromoCode = codeType === "2" ? (totalCost * (100 - code)) / 100 : totalCost - code;
  //
  const priceAfterReservationDiscount = reservationDiscount
    ? (priceAfterPromoCode * (100 - reservationDiscount)) / 100
    : priceAfterPromoCode;
  //
  const firstPaid = (priceAfterPromoCode * upfront) / 100;
  const firstPaidWithShipping = firstPaid + shippingFees;
  //
  const reservationDeduction = (priceAfterPromoCode * reservationDiscount) / 100;
  const codeDeduction = (totalCost * code) / 100;
  //
  const lastPaid = (priceAfterPromoCode * (100 - upfront)) / 100 - reservationDeduction;
  //
  const cost =
    code === 0
      ? parseInt(totalCostAfterReservation) + parseInt(shippingFees)
      : parseInt(discountPrice) + parseInt(shippingFees);

  React.useEffect(() => {
    setTotalPrice(cost);
  }, [cost]);
  return (
    <div>
      <div className="fourth-step-wrapper">
        <div className="cost-top-wrappe">
          <div className="row">
            <div className="col-md-6 col-sm-12 cost-top-content">
              <span className="cost-top-content"> قيمة الطلب: {totalCost} جنيه</span>
            </div>
            {Boolean(code) && (
              <div className="col-md-6 col-sm-12 cost-top-content">
                <span className="cost-top-content">
                  {" "}
                  خصم الكوبون: {codeDeduction.toFixed(2)} جنيه ({code.toFixed(2)}%)
                </span>
              </div>
            )}
            {Boolean(reservationDiscount) && (
              <div className="col-md-6 col-sm-12 cost-top-content">
                <span className="cost-top-content">
                  خصم الدفعة المقدمة: {reservationDeduction.toFixed(2)} جنيه ({reservationDiscount.toFixed(2)}%)
                </span>
              </div>
            )}
            <div className="col-md-6 col-sm-12 cost-top-content">
              <span className="cost-t\=op-content">
                صافي قيمة الطلب: {priceAfterReservationDiscount.toFixed(2)} جنيه
              </span>
            </div>
            {Boolean(reservationDiscount) && (
              <div className="col-md-6 col-sm-12 cost-top-content">
                <span className="cost-top-content">الدفعة المقدمة: {firstPaid.toFixed(2)} جنيه</span>
              </div>
            )}
            {Boolean(reservationDiscount) && (
              <div className="col-md-6 col-sm-12 cost-top-content">
                <span className="cost-top-content">المبلغ المتبقي: {lastPaid.toFixed(2)} جنيه</span>
              </div>
            )}
            <div className="col-md-6 col-sm-12 cost-top-content">
              <span className="cost-top-content">رسوم توصيل: {shippingFees.toFixed(2)} جنيه</span>
            </div>
          </div>
        </div>
        <div className="flex-center">
          <hr />
        </div>
        <div>
          <div className="cost-top-wrapper">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <h5 className=" bold">
                  المبلغ المطلوب سداده:{" "}
                  {Boolean(reservationMethod)
                    ? firstPaidWithShipping.toFixed(2)
                    : (priceAfterPromoCode + shippingFees).toFixed(2)}{" "}
                  جنيه
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthStep;
