import axios from 'axios';
import { myToast } from '../../components/Toast';
import * as types from './types';

export const addToCart = (quantity, itemId) => dispatch => {
    axios.post(`https://bluepointwear.com/backend/ar/api/addOrUpdateCart/${itemId}`, { quantity }, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => {
            dispatch({
                type: types.ADD_AND_RETURN
            })
            myToast('تم إضافة المنتج للسلة بنجاح')
        })
}

export const addToContinue = (quantity, itemId) => dispatch => {
    axios.post(`https://bluepointwear.com/backend/ar/api/addOrUpdateCart/${itemId}`, { quantity }, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => {
            dispatch({
                type: types.ADD_AND_COINTINUE
            })
            myToast('تم إضافة المنتج للسلة بنجاح')
        })
}

export const updateCart = (quantity, itemId, theIndex) => dispatch => {
    axios.post(`https://bluepointwear.com/backend/ar/api/addOrUpdateCart/${itemId}`, { quantity }, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => {
            dispatch({
                type: types.UPDATE_QUANTITY,
                payload: { quantity, theIndex }
            })
            myToast('تم التعديل بنجاح')
        })
}

export const setQuantityLoading = () => {
    return {
        type: types.QUANTITY_LOADING
    }
}

export const getCart = () => dispatch => {
    axios.get(`https://bluepointwear.com/backend/ar/api/cart`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => {
            dispatch({
                type: types.CARTS_LOADING,
                payload: res.data
            })
            dispatch({
                type: types.GET_CARTS,
                payload: res.data
            })
        })
        .catch(error => console.log(error?.response?.message))
}

export const getWishlist = () => dispatch => {
    axios.get(`https://bluepointwear.com/backend/ar/api/wishlist`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => {
            dispatch({
                type: types.CARTS_LOADING,
                payload: res.data
            })
            dispatch({
                type: types.WISHLIST,
                payload: res.data
            })
        })
        .catch(error => console.log(error?.response?.message))
}

export const deleteFromCart = (itemId) => dispatch => {
    axios.post(`https://bluepointwear.com/backend/ar/api/deleteFromCart/${itemId}`, {}, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => {
            dispatch({
                type: types.DELETE_PRODUCT
            })
            myToast('تم إزالة العنصر من السلة بنجاح')
        })
}

export const wishListAddOrRemove = (productId) => dispatch => {
    axios.post(`https://bluepointwear.com/backend/ar/api/wishlistAddOrRemove/${productId}`, {}, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => {
            dispatch({
                type: types.WISHLIST_DELETE
            })
            res.data?.msg === 'Added Successfully.' ?
                myToast("تم إضافة المنتج إلى المفضلة بنجاح") :
                myToast("تم إزالة المنتج من المفضلة بنجاح")
        })
}

