import axios from 'axios';
import { myToast, myError } from '../../components/Toast';
import * as types from './types';
const lang = localStorage.getItem('i18nextLng');

export const getUsers = (currentPage, limit) => dispatch => {
  axios.get(`https://matjrna.ml/api/v1/user?page=${currentPage}&limit=${limit}&sort=descending`)
    .then(res => {
      dispatch({
        type: types.USERS_LOADING,
        payload: res.data
      })
      dispatch({
        type: types.GET_USERS,
        payload: res.data
      })
    })
    .catch(error => myError(error?.response?.message))
}

export const getNews = () => dispatch => {
  axios.get(`https://bluepointwear.com/backend/ar/api/news`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
    .then(res => {
      dispatch({
        type: types.ARTICLE_LOADING,
        payload: res.data
      })
      dispatch({
        type: types.GET_ARTICLES,
        payload: res.data
      })
    })
    .catch(error => myError(error?.response?.message))
}

export const getNewById = (id) => dispatch => {
  axios.get(`https://bluepointwear.com/backend/ar/api/news/${id}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
    .then(res => {
      dispatch({
        type: types.ARTICLE_LOADING,
        payload: res.data
      })
      dispatch({
        type: types.GET_ARTICLE_BY_ID,
        payload: res.data
      })
    })
    .catch(error => myError(error?.response?.message))
}


export const deleteUser = (id) => dispatch => {
  axios.delete(`https://matjrna.ml/api/v1/user/${id}`)
    .then(res => {
      dispatch({
        type: types.DELETE_USER,
        payload: res
      })
      myToast(lang === 'ar' ? 'تم حذف المستخدم' : 'User has been deleted')
    })
    .catch(error => myError(error?.response?.message))
}