/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../components/ProductsCard";
import AppSpinner from "../components/Spinner/Spinner";
import { getOffers, setLoading } from "../redux/actions/productsActions";
import { getProducts } from "../redux/actions/productsActions";
import { getUserById, updateUser } from "../redux/actions/usersActions";
import axios from "axios";

const Products = () => {
  const dispatch = useDispatch();
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const productsRef = React.useRef();
  const [showDown, setShowDown] = React.useState(false);
  const [viewSubs, setViewSubs] = React.useState(true);
  const products = useSelector((state) => state.products.products?.products);
  const sub_category = useSelector((state) => state.users.user?.sub_category);
  const loading = useSelector((state) => state.products.loading);
  const user = useSelector((state) => state.users.user);
  const categoriesPart = useSelector((state) => state?.products?.offers?.category);
  const theAddress = JSON.parse(user?.address ? user?.address : '{"gov":"", "city":"", "city":""}');
  const categories = categoriesPart?.children;
  const token = localStorage.getItem("token");

  React.useEffect(() => {
    dispatch(getOffers());
    if (!sessionStorage.getItem("Offer")) {
      axios.get(`https://bluepointwear.com/backend/ar/api/shop-counter`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    sessionStorage.setItem("Offer", true);
  }, []);
  const [name, setName] = React.useState();
  const [age, setAge] = React.useState();
  const [email, setEmail] = React.useState(user?.email);
  const [mobile, setMobile] = React.useState(user?.mobile);
  const [near, setNear] = React.useState();
  const [gender, setGender] = React.useState();
  const [phone, setPhone] = React.useState(user?.phone);
  const [category, setCategory] = React.useState();
  const [mohafza, setMohafza] = React.useState(theAddress?.gov);
  const [city, setCity] = React.useState();
  const [district, setDistrict] = React.useState();
  const [street, setStreet] = React.useState();
  const [building, setBuilding] = React.useState();
  const [floor, setFloor] = React.useState();
  const [home, setHome] = React.useState();
  const [startTime, setStartTime] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const [categoryId, setCategoryId] = React.useState(1);
  const [boys, setBoys] = React.useState(null);
  const [boysToFilter, setBoysToFilter] = React.useState();
  const [stageName, setStageName] = React.useState(user?.sub_category?.name);
  const currentCat = categories?.find((item) => item.id === category);
  const currentSub = currentCat?.children?.find((item) => item.id == boys);

  React.useState(() => {
    document.title = "Blue Point | الرئيسية";
  }, []);

  React.useEffect(() => {
    setViewSubs(false);
    setTimeout(() => {
      setViewSubs(true);
    }, []);
  }, [currentSub]);

  React.useEffect(() => {
    setPhone(user?.phone);
    setAge(user?.age);
    setMobile(user?.mobile);
    setName(user?.name);
    setEmail(user?.email);
    setGender(user?.gender);
    setCategoryId(1);
    setCity(theAddress?.city);
    setBuilding(theAddress?.home_number);
    setDistrict(theAddress?.area);
    setStreet(theAddress?.street);
    setStartTime(theAddress?.from);
    setEndTime(theAddress?.to);
    setFloor(theAddress?.floor);
    setMohafza(theAddress?.gov);
    setHome(theAddress?.apartment);
    setNear(theAddress?.near);
  }, [user]);

  React.useEffect(() => {
    dispatch(setLoading());
    dispatch(getProducts(categoryId));
  }, [category, categoryId]);

  React.useEffect(() => {
    if (boys !== null) {
      setBoysToFilter(sub_category?.children?.filter((row) => parseInt(boys) === row.id));
    }
  }, [boys]);

  const getProfile = () => {
    dispatch(getUserById());
  };

  React.useEffect(() => {
    dispatch(setLoading());
  }, [category]);

  const handleRadioChange = (e) => {
    setCategoryId(e.target.value);
  };
  const handleMainRadioChange = (e) => {
    setCategoryId(e.target.value);
    setBoys(e.target.value);
  };
  const handleStageChange = (id, row) => {
    setCategory(id);
    setCategoryId(id);
    setStageName(row.name);
    setShowDown(false);
  };

  return (
    <div className="container">
      <h1 className="school-name">متجر بلوبوينت</h1>
      <h3 className="text-center mb-4">الاقسام</h3>
      <div className="row">
        {categories?.map((row, index) => {
          return (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-12 flex-center">
              <div
                className="store-category-card"
                onClick={() => {
                  handleStageChange(row.id, row);
                  scrollToRef(productsRef);
                }}
                style={category === row?.id ? { border: "2px solid #1f61aa" } : null}
              >
                <div className="overlay" />
                <img src={row?.photo_original_path} alt="" />
                <h5 className="text-center mt-2 bold">{row.name}</h5>
              </div>
            </div>
          );
        })}
      </div>

      <h3 ref={productsRef} className="text-center mb-4 pt-2">
        المنتجات
      </h3>

      <hr />
      <label>فلتر حسب</label>
      <div className="flex-between">
        <div className="flex-center">
          <div className="seasons-filter-wrapper">
            {currentCat ? (
              <>
                <input
                  onClick={handleMainRadioChange}
                  defaultChecked={true}
                  type="radio"
                  id="alls"
                  name="mainCat"
                  value={currentCat?.id}
                />
                <label htmlFor="alls">الكل</label>
              </>
            ) : null}
            {currentCat?.children?.map((row, index) => {
              return row?.ref_code === 1 ? (
                <div key={index}>
                  <input
                    onClick={handleMainRadioChange}
                    type="radio"
                    id={`main${index}`}
                    name="mainCat"
                    value={row.id}
                  />
                  <label htmlFor={`main${index}`}>{row.name}</label>
                </div>
              ) : null;
            })}
          </div>
        </div>

        {viewSubs && (
          <div className="flex-center">
            <div className="seasons-filter-wrapper">
              {currentSub ? (
                <>
                  <input
                    onClick={() => setCategoryId(currentSub.id)}
                    defaultChecked={true}
                    type="radio"
                    id="subs"
                    name="subCats"
                    value={currentSub?.id}
                  />
                  <label htmlFor="subs">الكل</label>
                </>
              ) : null}
              {currentSub?.children?.map((row, index) => {
                return row?.ref_code === 1 ? (
                  <div key={index}>
                    <input
                      onClick={() => setCategoryId(row.id)}
                      type="radio"
                      id={`sub${index}`}
                      name="subCats"
                      value={row.id}
                    />
                    <label htmlFor={`sub${index}`}>{row.name}</label>
                  </div>
                ) : null;
              })}
            </div>
          </div>
        )}
        <div className="choose-color">
          <div className="flex-between">
            <div className="seasons-filter-wrapper">
              {boysToFilter?.length > 0 ? (
                <>
                  <input
                    onClick={(e) => {
                      handleRadioChange(e);
                    }}
                    defaultChecked={true}
                    type="radio"
                    id="all"
                    name="radioFruit"
                    value={boysToFilter ? boysToFilter[0]?.id : null}
                  />
                  <label htmlFor="all">الكل</label>
                </>
              ) : null}
              {boysToFilter
                ? boysToFilter[0]?.children?.map((row, index) => {
                    return row?.ref_code === 1 ? (
                      <div key={index}>
                        <input onClick={handleRadioChange} type="radio" id={index} name="radioFruit" value={row.id} />
                        <label htmlFor={index}>{row.name}</label>
                      </div>
                    ) : null;
                  })
                : null}
            </div>
          </div>
        </div>

        <p className="mb-0">{products?.length} منتج</p>
      </div>
      <hr />

      <div className="row ">
        {loading ? (
          <div style={{ padding: "100px 0px" }}>
            <AppSpinner />
          </div>
        ) : products?.length < 1 ? (
          <h1 style={{ padding: "200px 0px" }} className="text-center">
            لا توجد منتجات
          </h1>
        ) : (
          products?.map((row, index) => {
            return (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 flex-center">
                <ProductCard
                  code={row?.code}
                  isDiscount={row.has_discount}
                  fav={row.is_favorite}
                  id={row.id}
                  title={row.title}
                  price={row.price}
                  url={row.main_photo_original_path}
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Products;
