import { CHANGE_PASSWORD, FORGET_PASSWORD, FORGET_PASSWORD_FAIL, LOADING, LOGIN, LOGIN_ERROR, REGISTER, REGISTER_FAIL, START_VERIFY, VERIFY } from '../actions/types'

const initialState = {
     user: [],
     logged: false,
     loading: false,
     verify: false,
     forgetSent: false,
     passwordChanged: false,
     listener: 1,
     registerDone: false,
     registerErrors: [],
     forgetErrors: []
}

const brandsReducer = (state = initialState, action) => {
     switch (action.type) {
          case LOGIN:
               return {
                    ...state,
                    user: action.payload,
                    logged: true,
                    loading: false,
               };
          case FORGET_PASSWORD:
               return {
                    ...state,
                    loading: false,
                    forgetSent: true
               };
          case REGISTER:
               return {
                    ...state,
                    loading: false,
                    registerDone: true
               };
          case FORGET_PASSWORD_FAIL:
               return {
                    ...state,
                    loading: false,
                    forgetErrors: action.payload?.response?.data?.errors
               };
          case REGISTER_FAIL:
               return {
                    ...state,
                    loading: false,
                    registerErrors: action.payload?.response?.data?.errors
               };
          case CHANGE_PASSWORD:
               return {
                    ...state,
                    loading: false,
                    passwordChanged: true
               };
          case LOGIN_ERROR:
               return {
                    ...state,
                    listener: Math.random(),
                    loading: false,
                    errorMessage: action?.payload?.response?.status === 403 ? action?.payload?.response?.data?.msg : 'الرقم غير متصل بحساب'
               };
          case LOADING:
               return {
                    ...state,
                    loading: true,
               };
          case START_VERIFY:
               return {
                    ...state,
                    loading: false,
               };
          case VERIFY:
               return {
                    ...state,
                    loading: false
               };
          default:
               return state
     }

}

export default brandsReducer;