import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppSpinner from '../components/Spinner/Spinner';
import parse from 'html-react-parser'
import { setLoading } from '../redux/actions/loading';
import { getTerms } from '../redux/actions/termsActions';

const Terms = () => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setLoading())
        dispatch(getTerms())
    }, [dispatch])
    React.useState(() => {
        document.title = 'Blue Point | الشروط والاحكام'
    }, [])
    const terms = useSelector(state => state.terms.terms?.setting?.terms)
   
    return (
        terms?
            <div className='container'>
                <h2 className='text-center bold mb-2 mt-3'>سياسة الخصوصية</h2>
                {
                    terms?.length > 3 ?
                        parse(terms) : null
                }
            </div> : <AppSpinner />
    )
}

export default Terms;