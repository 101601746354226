import React from 'react';
import emailjs from 'emailjs-com'
import { myToast } from './Toast';

function SendEmail() {
    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_qci53bn', 'template_4dvz4ga', e.target, 'XhKhT0YRioY1r7ot7')
        .then(() => myToast('تم إرسال الرسالة بنجاح'))
        .catch(() => console.log('Error'))

    }
    return (
        <form onSubmit={handleSubmit}>
            <label>الاسم</label>
            <input name='name' className='form-control app-input' />
            <label>البريد الإلكتروني</label>
            <input name='email' className='form-control app-input' />
            <label>الرسالة</label>
            <textarea name='message' className='form-control app-input' rows={3} />
            <button className='login-button'>ارسال</button>
        </form>
    )
}

export default SendEmail