/* eslint-disable no-mixed-operators */
import React from "react";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getWishlist, wishListAddOrRemove } from "../redux/actions/cartsActions";
import { setLoading } from "../redux/actions/productsActions";
import AppSpinner from "./Spinner/Spinner";
import ConfirmationModal from "./ConfirmationModal";
import noItems from "../assets/broken-heart.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CartContent = ({ setCartLength, checkLength }) => {
  const dispatch = useDispatch();
  const [mobile, setMobile] = React.useState();
  const cartItems = useSelector((state) => state.wishlist.wishlist);
  const loading = useSelector((state) => state.wishlist.loading);
  const updated = useSelector((state) => state.wishlist.updated);
  const [show, setShow] = React.useState(false);
  const history = useHistory();
  const [id, setId] = React.useState("");

  React.useEffect(() => {
    dispatch(setLoading());
    dispatch(getWishlist());
  }, [updated, dispatch]);

  React.useState(() => {
    if (window.innerWidth < 995) {
      setMobile(true);
    }
  }, []);

  const removeItem = (id) => {
    setId(id);
    setShow(true);
  };

  React.useEffect(() => {
    if (checkLength) {
      setCartLength(cartItems?.length);
    }
  }, [cartItems, setCartLength, checkLength]);

  return loading ? (
    <div className="flex-center">
      <AppSpinner />
    </div>
  ) : cartItems?.length === 0 ? (
    <div>
      <h4 className="text-center">
        المفضلة فارغة, للتسوق
        <span onClick={() => history.push("/")} className="blue pointer">
          {" "}
          اضغط هنا
        </span>
      </h4>
      <div className="flex-center">
        <img alt="img" src={noItems} />
      </div>
    </div>
  ) : (
    <div className="cart-content flex-center mt-4">
      <table className={`table table-borderless ${mobile ? null : "table-hover"} cart-table table-responsive`}>
        <ConfirmationModal
          content="إزالة المنتج من المفضلة"
          show={show}
          setShow={setShow}
          theAction={wishListAddOrRemove}
          id={id}
        />
        <thead>
          <tr>
            <th scope="col">المنتج</th>
            <th scope="col">حذف</th>
          </tr>
        </thead>
        <tbody>
          {cartItems?.map((row, index) => {
            return (
              <tr key={index}>
                <th scope="row">
                  <div className="cart-image-title">
                    <img
                      alt="shirt"
                      onClick={() => history.push(`view-product/${row.product_id}`)}
                      src={row.photo}
                      className="cart-table-image pointer"
                    />
                    <div>
                      <h3
                        className="cart-product-title pointer"
                        onClick={() => history.push(`/view-product/${row.product_id}`)}
                      >
                        {row.title}
                      </h3>
                      <h6 className="cart-category-title">{row.category_parent_name}</h6>
                    </div>
                  </div>
                </th>
                <td>
                  <div className="price-cart">
                    <MdDelete onClick={() => removeItem(row.product_id)} className="delete-icon" />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CartContent;
