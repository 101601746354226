import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendInquiry } from "../redux/actions/inquiriesActions";

const Inquiries = () => {
  const [message, setMessage] = React.useState("");
  const [orders, setOrders] = React.useState([]);
  const [type, setType] = React.useState(0);
  const [myError, setMyerror] = React.useState("");
  const [order_id, setOrderId] = React.useState(0);
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === 0) {
      setMyerror("يجب اختيار نوع الرسالة اولاً");
    } else {
      setMyerror(" ");
      dispatch(sendInquiry({ message, type, order_id }));
    }
  };
  React.useState(() => {
    document.title = "Blue Point | الشكاوى والمقترحات";
  }, []);
  React.useEffect(() => {
    if (type !== 0) {
      setMyerror("");
    }
  }, [type]);

  const getOrders = (currentPage) => (dispatch) => {
    axios
      .get(`https://bluepointwear.com/backend/ar/api/orders?page=${currentPage}&pagintion=100`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setOrders(res.data?.orders?.data);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    dispatch(getOrders());
  }, []);

  return (
    <div className="container">
      <div className="inquiries-wrapper">
        <h2 className="text-center bold mt-4">تواصل معنا</h2>
        <h4 className="text-center mb-5">للشكاوى والاستفسارات</h4>

        <div className="flex-between inquiries-radios-wrapper">
          <div className="checkbox-wrapper mb-2">
            <input onClick={() => setType(1)} className="app-checkbox" type="radio" name="inquiry-type" />
            <label>مقترح </label>
          </div>

          <div className="checkbox-wrapper mb-2">
            <input onClick={() => setType(2)} className="app-checkbox" type="radio" name="inquiry-type" />
            <label>شكوى </label>
          </div>
        </div>

        <p className="register-error mb-4">{myError}</p>

        <label>اسم المستخدم</label>
        <input className="form-control app-input" defaultValue={user?.name} disabled />
        <label>البريد الإلكتروني</label>
        <input className="form-control app-input" defaultValue={user?.email} disabled />
        <label>رقم الهاتف</label>
        <input className="form-control app-input" defaultValue={user?.mobile} disabled />
        <label>الجهة</label>
        <input className="form-control app-input" defaultValue={user?.establishment} disabled />
        <form onSubmit={handleSubmit}>
          <label>رقم الطلب</label>
          {/* <input
            required
            className="form-control app-input"
            placeholder="رقم الطلب"
            type="number"
          /> */}
          <select onChange={(e) => setOrderId(e.target.value)} className="form-select app-input">
            className="form-select app-input"
            <option>رقم الطلب</option>
            {orders?.map((item, index) => {
              return (
                <option value={item?.id} key={index}>
                  {item?.id}
                </option>
              );
            })}
          </select>
          <label>الرسالة</label>
          <textarea
            onChange={(e) => setMessage(e.target.value)}
            required
            className="form-control app-input"
            rows={4}
            placeholder="نص الرسالة"
          />
          <button className="login-button mt-3 mb-3">ارسال</button>
        </form>
      </div>
    </div>
  );
};

export default Inquiries;
