import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import errorImage from "../assets/errorimg.png";

const NewCard = ({ date, title, price, url, id }) => {
  const history = useHistory();
  const handleImageError = (e) => {
    e.target.src = errorImage;
  };
  return (
    <div onClick={() => history.push(`/view-new/${id}`)} className="news-card">
      <div className="flex-center">
        <img onError={handleImageError} alt="kids" src={url} className="sub-cat-card-image" />
      </div>
      <p className="text-center mt-2 mb-0 produce-title sub-cat-title">{title}</p>
      <p className="text-center mt-0 sub-cat-title product-price">{new Date(date).toLocaleDateString("en-GB")}</p>
      <div className="flex-center">
        <button className="login-button view-product-button">عرض الخبر</button>
      </div>
    </div>
  );
};

export default NewCard;
