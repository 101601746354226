import React from "react";
import { useHistory } from "react-router-dom";

function PaymentSuccess() {
  const [counter, setCounter] = React.useState(10);
  const history = useHistory();
  React.useEffect(() => {
    if (counter !== 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } else {
      history.push("/");
    }
  }, [counter, history]);
  return (
    <div className="payment-success-page">
      <div
        style={{
          border: "1px solid #000",
          padding: "50px",
          borderRadius: "20px",
          background: "rgba(2500,2500,2500, 0.5)",
        }}
      >
        <h2 className="text-center mb-3 bold">
          لقد تم الدفع وتسجيل الطلب بنجاح
        </h2>
        <h2 className="text-center blue">{counter}</h2>
        <div className="flex-center">
          <button
            style={{ height: "50px", width: "200px", fontSize: "20px" }}
            onClick={() => history.push("/")}
            className="login-button mt-3"
          >
            تخطي
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
