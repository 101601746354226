import React from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { changePassword } from '../redux/actions/usersActions';
import PasswordInput from '../components/PasswordInput';

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = React.useState();
    const [newPassword, setNewPassword] = React.useState();
    const [newPasswordConfirmation, setNewPasswordConfirmation] = React.useState();
    const changeError = useSelector(state => state?.users?.changePasswordError);
    const [redirect, setRedirect] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(changePassword(setLoading, setRedirect, { old_password: oldPassword, password: newPassword, password_confirmation: newPasswordConfirmation }))
    }

    React.useEffect(() => {
        if (redirect) {
            history.push('/my-profile')
        }
    }, [redirect, history])


    return (
        <div className='container'>
            <div className='flex-center'>
            </div>
            <h2 className='text-center bold'>حسابي</h2>
            <h4 className='text-center bold mb-4'>تغيير كلمة المرور</h4>
            <div className='row update-info-container'>
                <form onSubmit={handleSubmit}>
                    <div className='row mt-3'>
                        <label>كلمة المرور الحالية</label>
                        <PasswordInput onChange={(e) => setOldPassword(e.target.value)} placeholder='كلمة المرور الحالية' />
                        <label>كلمة المرور الجديدة</label>
                        <PasswordInput onChange={(e) => setNewPassword(e.target.value)} placeholder='كلمة المرور الجديدة' />
                        <label>تأكيد كلمة المرور الجديدة</label>
                        <PasswordInput onChange={(e) => setNewPasswordConfirmation(e.target.value)} placeholder='كلمة المرور الجديدة' />
                        <button className='login-button profile-buttons'>{loading ? <BiLoaderAlt className='login-loader' /> : "تغيير كلمة السر"}</button>
                        <p className='login-error'>{changeError}</p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChangePassword;