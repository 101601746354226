import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { BiLoaderAlt, BiCheckCircle } from "react-icons/bi";

const ThirdStep = ({ setTheCode, couponText, setCouponText, setCodeType, price }) => {
  const dispatch = useDispatch();
  const [code, setCode] = React.useState();
  const [err, setErr] = React.useState("");
  const [succ, setSucc] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const testCode = () => (dispatch) => {
    setLoading(true);
    axios
      .post(
        `https://bluepointwear.com/backend/ar/api/coupon`,
        { code, price },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setErr("");
        setSucc("تم تطبيق كود الخصم بنجاح");
        setTheCode(res.data?.amount);
        setCodeType(res?.data?.type);
        setLoading(false);
        setSuccess(true);
        setCouponText(code);
      })
      .catch((res) => {
        setErr(res.response?.data?.msg);
        setSucc("");
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(testCode());
  };

  return (
    <div className="mb-3">
      <form onSubmit={handleSubmit}>
        <label>هل لديك كوبون خصم</label>
        <div className="flex-center">
          <input
            required
            type="text"
            onChange={(e) => setCode(e.target.value)}
            className="form-control app-input pass-input"
            placeholder="الكوبون"
          />
          <button disabled={success ? true : false} className="apply-coupon">
            {loading ? (
              <BiLoaderAlt className="login-loader" />
            ) : success ? (
              <BiCheckCircle size={24} />
            ) : (
              "برجاء الضغط لتفعيل الكوبون"
            )}
          </button>
        </div>
      </form>
      <span className="register-error mt-0 mb-4">{err}</span>
      <span className="succes-coupon">{succ}</span>
    </div>
  );
};

export default ThirdStep;
