import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import RedStar from "../components/RedStar";
import AppSpinner from "../components/Spinner/Spinner";
import { myError } from "../components/Toast";
import { getAreas } from "../redux/actions/citiesActions";
import { setLoading } from "../redux/actions/loading";
import { getUserById, updateUser } from "../redux/actions/usersActions";

const MyProfileBranch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.users.user);
  const categories = useSelector((state) => state.categories.categories?.categories);
  const mohafzat = useSelector((state) => state.cities.cities?.cities);
  const loading = useSelector((state) => state.users.loading);
  const cities = useSelector((state) => state.cities.area?.areas);
  const theAddress = JSON.parse(user?.address ? user?.address : '{"gov":"", "city":"", "city":""}');
  const [name, setName] = React.useState();
  const [age, setAge] = React.useState();
  const [email, setEmail] = React.useState(user?.email);
  const [mobile, setMobile] = React.useState(user?.mobile);
  const [near, setNear] = React.useState();
  const [gender, setGender] = React.useState();
  const [phone, setPhone] = React.useState(user?.phone === null ? "" : user?.phone);
  const [category, setCategory] = React.useState();
  const [mohafza, setMohafza] = React.useState(theAddress?.gov);
  const [city, setCity] = React.useState();
  const [district, setDistrict] = React.useState();
  const [street, setStreet] = React.useState();
  const [building, setBuilding] = React.useState();
  const [floor, setFloor] = React.useState();
  const [home, setHome] = React.useState();
  const [startTime, setStartTime] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const choosenGov = mohafzat?.find((gov) => gov.text === mohafza);
  const [renderSelect, setRenderSelect] = React.useState(true);

  React.useEffect(() => {
    setRenderSelect(false);
    setTimeout(() => {
      setRenderSelect(true);
    }, 1000);
  }, []);
  React.useState(() => {
    document.title = "Blue Point | صفحتي";
  }, []);
  React.useEffect(() => {
    dispatch(getAreas(choosenGov?.id));
  }, [choosenGov, dispatch]);

  const redirect = () => {
    history.push("/");
  };

  React.useEffect(() => {
    setPhone(user?.phone);
    setAge(user?.age);
    setMobile(user?.mobile);
    setName(user?.name);
    setEmail(user?.email);
    setGender(user?.gender);
    setCategory(user?.category_id);
    setCity(theAddress?.city);
    setBuilding(theAddress?.home_number);
    setDistrict(theAddress?.area);
    setStreet(theAddress?.street);
    setStartTime(theAddress?.from);
    setEndTime(theAddress?.to);
    setFloor(theAddress?.floor);
    setMohafza(theAddress?.gov);
    setHome(theAddress?.apartment);
    setNear(theAddress?.near);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getProfile = () => {
    dispatch(getUserById());
  };

  const data = {
    name,
    age,
    gender,
    email,
    mobile,
    category,
    mohafza,
    city,
    district,
    street,
    building,
    floor,
    near,
    startTime,
    endTime,
    home,
    phone,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parseInt(category) === 0 || category === null || category === "null") {
      myError("برجاء اختيار المرحلة");
    } else if (parseInt(gender) === 0 || gender === null || gender === "null") {
      myError("برجاء اختيار النوع");
    } else if (parseInt(mohafza) === 0 || mohafza === "") {
      myError("برجاء اختيار المحافظة");
    } else if (parseInt(city) === 0 || city === "") {
      myError("برجاء اختيار المدينة");
    } else {
      dispatch(setLoading());
      dispatch(updateUser(data, true, getProfile, redirect));
    }
  };

  return user?.id > 0 ? (
    <div className="container mt-3 mb-5">
      <div className="flex-center"></div>
      <h2 className="text-center bold">حسابي</h2>
      <h4 className="text-center bold mb-4">تعديل البيانات الشخصية</h4>
      <form onSubmit={handleSubmit}>
        <div className="row update-info-container">
          <div className="col-md-6">
            <label>الاسم بالكامل</label>
            <input
              disabled
              required
              value={user?.name}
              onChange={(e) => setName(e.target.value)}
              className="form-control app-input"
              placeholder="الاسم رباع"
            />
          </div>

          <div className="col-md-6">
            <label>كود الموظف</label>
            <input
              required
              value={user?.mobile}
              disabled
              onChange={(e) => setMobile(e.target.value)}
              className="form-control app-input"
              placeholder="رقم الهاتف"
            />
          </div>
          <div className="col-md-12">
            <label>الفرع</label>
            <input
              disabled
              value={user?.branch?.name}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control app-input"
              placeholder="الفرع"
            />
          </div>
        </div>
      </form>
    </div>
  ) : (
    <AppSpinner />
  );
};

export default MyProfileBranch;
