/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AppSpinner from "../components/Spinner/Spinner";
import { getProductById, getSizes, setLoading } from "../redux/actions/productsActions";
import { getProducts } from "../redux/actions/productsActions";
import { bigToast, myError, myToast } from "./Toast";

const ProductsForExchange = ({ setShow, orderId, oldProduct, data, setData, setBalanceData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const products = useSelector((state) => state.products.products?.products);
  const product = useSelector((state) => state.products.product?.product);
  const sizes = useSelector((state) => state.products.sizes?.sizes);
  const loading = useSelector((state) => state.products.loading);
  const user = useSelector((state) => state.users.user);
  const categories = useSelector((state) => state.categories.categories?.categories);
  const [categoryId, setCategoryId] = React.useState(user?.category_id);
  const [choosenProduct, setChoosenProduct] = React.useState(" ");
  const [choosenColor, setChoosenColor] = React.useState(" ");
  const [choosenSize, setChoosenSize] = React.useState(" ");
  const [newQuantity, setNewQuantity] = React.useState("0");
  const [oldQuantity, setOldQuantity] = React.useState("0");
  const [rendered, setRendered] = React.useState(false);
  const choosenProductSpecs = products?.find((item) => item.id === choosenProduct);
  const [choosenItem, setChoosenItem] = React.useState(null);
  const [reason, setReason] = React.useState("");
  const isExpired = Date.now() - new Date(choosenProductSpecs?.expired_at) > 0 ? true : false;
  let price = choosenItem?.price * newQuantity;

  React.useEffect(() => {
    setChoosenItem(sizes?.find((size) => size?.size?.id === parseInt(choosenSize)));
  }, [choosenSize, newQuantity]);

  const requestData = {
    oldItemId: oldProduct.item_id,
    newItemId: choosenItem?.id,
    oldItemQuantity: oldQuantity,
    newItemQuantity: newQuantity,
    reason: reason,
  };

  const arrayOfColors = product?.items?.map((row) => row.color);

  const uniqueColors = arrayOfColors?.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  React.useState(() => {
    document.title = "Blue Point | الرئيسية";
  }, []);

  React.useEffect(() => {
    dispatch(getProductById(choosenProduct, () => {}));
    setNewQuantity("0");
    setOldQuantity("0");
  }, [choosenProduct]);

  React.useEffect(() => {
    dispatch(getSizes(choosenProduct, choosenColor));
  }, [choosenColor]);

  React.useEffect(() => {
    dispatch(setLoading());
    dispatch(getProducts(categoryId));
  }, [categoryId]);

  const handleSubmit = () => {
    if (
      choosenColor === " " ||
      choosenProduct === " " ||
      choosenSize === " " ||
      parseInt(oldQuantity) < 1 ||
      parseInt(newQuantity) < 1 ||
      newQuantity === ""
    ) {
      myError("برجاء اختار المنتج باللون المقاس والكميات");
    } else if (reason === "") {
      myError("برجاء اختيار السبب");
    } else if (newQuantity > 100) {
      myError("الحد الاقصى 100 قطعة");
    } else {
      setRendered(true);
      if (data?.length === 0) {
        setData(data.concat([requestData]));
      } else {
        for (var i = 0; i < data?.length; i++) {
          if (data[i].oldItemId !== oldProduct?.item_id) {
            setData(data.concat([requestData]));
          } else {
            data.splice(i, 1);
            setData(data.concat([requestData]));
          }
        }
      }
    }
  };

  const sendRequest = (formData) => (dispatch) => {
    axios
      .post(`https://bluepointwear.com/backend/ar/api/calculate-replace`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setBalanceData(res.data);
        bigToast("تم الحفظ بنجاح برجاء استكمال عملية الاستبدال");
        setShow(false);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    const formData = new FormData();
    formData.append("order_id", orderId);
    data.map((row, index) => formData.append(`items[old_item][${index + 1}][id]`, row.oldItemId));
    data.map((row, index) => formData.append(`items[old_item][${index + 1}][quantity]`, row.oldItemQuantity));
    data.map((row, index) =>
      row.newItemId ? formData.append(`items[new_item][${index + 1}][id]`, row.newItemId) : null
    );
    data.map((row, index) =>
      row.newItemId ? formData.append(`items[new_item][${index + 1}][quantity]`, row.newItemQuantity) : null
    );
    if (rendered) {
      dispatch(sendRequest(formData));
    }
  }, [data]);

  let ChoosenQuan = [];
  React.useEffect(() => {
    for (let i = 0; i < 9; i++) {
      ChoosenQuan.push(i);
      return ChoosenQuan;
    }
  }, []);

  function handleArr(n) {
    let arr = [];
    for (let index = 1; index < n + 1; index++) {
      arr.push(index);
    }
    return arr;
  }

  return (
    <div className="container">
      <div className="row flex-center">
        <div className="col-md-12">
          <select onChange={(e) => setReason(e.target.value)} className="form-select app-select mb-3">
            <option value="">السبب</option>
            <option value="عيب في الصناعه">عيب في الصناعه</option>
            <option value="مقاس خاطئ">مقاس خاطئ</option>
            <option value="لون خاطئ">لون خاطئ</option>
            <option value="منتج خاطئ">منتج خاطئ</option>
            <option value="سبب اخر">سبب اخر</option>
          </select>
        </div>
        <div className="col-md-12">
          <select
            defaultValue={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
            className="form-select app-input"
          >
            {categories?.map((category) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div style={{ maxHeight: "68vh", overflow: "scroll" }}>
        {loading || !products ? (
          <AppSpinner />
        ) : (
          <table
            style={{ minWidth: "800px" }}
            className={`table table-borderless exchange-table table-hover table-responsive`}
          >
            <thead>
              <tr>
                <th scope="col">المنتج</th>
                <th scope="col">اختيار المنتج</th>
                <th scope="col">اللون</th>
                <th scope="col">المقاس</th>
                <th scope="col">الكمية الجديدة</th>
                <th scope="col">كمية المرتجع</th>
              </tr>
            </thead>
            <tbody>
              {products?.map((row, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">
                      <div className="cart-image-title">
                        <img
                          alt="shirt"
                          onClick={() => history.push(`view-product/${row.product_id}`)}
                          src={row.main_photo_thumbnail_path}
                          className="cart-table-image pointer"
                        />
                        <div>
                          <h3
                            className="cart-product-title pointer"
                            onClick={() => history.push(`/view-product/${row.product_id}`)}
                          >
                            {row.title}
                          </h3>
                          <h6 className="cart-category-title">{row.category_parent_name}</h6>
                          <span>{typeof price === "number" && row.id === choosenProduct ? price : null}</span>
                        </div>
                      </div>
                    </th>
                    <td className="exchange-radio-wrapper">
                      <input
                        type="radio"
                        name="product"
                        value={row.id}
                        onClick={(e) => setChoosenProduct(parseInt(e.target.value))}
                      />
                    </td>
                    {row.id === choosenProduct ? (
                      <td>
                        <select
                          onChange={(e) => setChoosenColor(e.target.value)}
                          className="form-select exchange-select"
                        >
                          {/* options */}
                          <option>اللون</option>
                          {uniqueColors?.map((color) => {
                            return (
                              <option value={color.id} key={color.id}>
                                {color.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                    ) : (
                      <td>
                        <div></div>
                      </td>
                    )}
                    {row.id === choosenProduct ? (
                      <td>
                        <select
                          onChange={(e) => setChoosenSize(e.target.value)}
                          className="form-select exchange-select"
                        >
                          {/* options */}
                          <option>المقاس</option>
                          {sizes?.map((color) => {
                            return (
                              <option value={color?.size.id} key={color?.size.id}>
                                {color?.size.name}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                    ) : (
                      <td>
                        <div></div>
                      </td>
                    )}

                    {row.id === choosenProduct ? (
                      isExpired ? (
                        <td>
                          <select
                            onChange={(e) => setNewQuantity(e.target.value)}
                            className="form-select exchange-select"
                          >
                            {/* options */}
                            <option value={0}>الكمية</option>
                            {handleArr(choosenItem?.quantity).map((number, index) => {
                              return (
                                <option key={index} value={number}>
                                  {number}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                      ) : (
                        <td>
                          <input
                            placeholder="الكمية"
                            type="number"
                            min={1}
                            onChange={(e) => setNewQuantity(e.target.value)}
                            className="form-control exchange-select"
                          />
                        </td>
                      )
                    ) : (
                      <td>
                        <div></div>
                      </td>
                    )}

                    {row.id === choosenProduct ? (
                      <td>
                        <select
                          onChange={(e) => setOldQuantity(e.target.value)}
                          className="form-select exchange-select"
                        >
                          <option value={0}>الكمية المسترجعة</option>
                          {handleArr(oldProduct?.sold_quantity).map((number, index) => {
                            return (
                              <option key={index} value={number}>
                                {number}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                    ) : (
                      <td>
                        <div></div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>

      <button onClick={handleSubmit} className="login-button exchange-button bold w-100">
        برجاء الضغط للحفظ و إستكمال عملية الاستبدال
      </button>
    </div>
  );
};

export default ProductsForExchange;
