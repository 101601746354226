export const ADD_CAT = "ADD_CAT",
  DELETE_CAT = "DElELTE_CAT",
  GET_CAT = "GET_CAT",
  UPDATE_CAT = "UPDATE_CAT",
  ADD_Proudct = "ADD_Proudct",
  DELETE_Proudct = "DElELTE_Proudct",
  GET_Proudct = "GET_Proudct",
  UPDATE_Proudct = "UPDATE_Proudct",
  REGISTER = "REGISTER",
  LOGIN = "LOGIN",
  LOGIN_SUCCES = "LOGIN_SUCCES",
  LOGIN_ERROR = "LOGIN_ERROR",
  LOGOUT = "LOGUT",
  FORGET_PASSWORD = "FORGET_PASSWORD";
export const GET_BRAND = "GET_BRAND";
export const ADD_BRAND = "ADD_BRAND";
export const DELETE_BRAND = "DELETE_BRAND";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const GET_SUB_CATS = "GET_SUB_CATS";
export const GET_SUB_CAT_BY_ID = "GET_SUB_CAT_BY_ID";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const ADD_SUB_CAT = "ADD_SUB_CAT";
export const DELETE_SUB_CAT = "DELETE_SUB_CAT";
export const UPDATE_SUB_CAT = "UPDATE_SUB_CAT";
export const GET_CITIES = "GET_CITIES";
export const ADD_CITY = "ADD_CITY";
export const DELETE_CITY = "DELETE_CITY";
export const UPDATE_CITY = "UPDATE_CITY";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDER_BY_ID = "GET_ORDER_BY_ID";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const LOADING = "LOADING";
export const GET_SHIPPERS = "GET_SHIPPERS";
export const ADD_SHIPPER = "ADD_SHIPPER";
export const DELETE_SHIPPERS = "DELETE_SHIPPER";
export const UPDATE_SHIPPER = "UPDATE_SHIPPER";
export const GET_SHIPPER_BY_ID = "GET_SHIPPER_BY_ID";
export const GET_ADMINS = "GET_ADMINS";
export const GET_ADMIN_BY_ID = "GET_ADMIN_BY_ID";
export const ADD_ADMIN = "ADD_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const GET_USERS = "GET_USERS";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const DELETE_USER = "DELETE_USER";
export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID";
export const GET_BRAND_BY_ID = "GET_BRAND_BY_ID";
export const GET_CITY_BY_ID = "GET_CITY_BY_ID";
export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID";
export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const GET_DISSCOUNTS = "GET_DISCOUNTS";
export const ADD_DISCOUNT = "ADD_DISCOUNT";
export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const GET_DISCOUNT_BY_ID = "GET_DISCOUNT_BY_ID";
export const GET_COUPONS = "GET_COUPONS";
export const ADD_COUPON = "ADD_CPUPON";
export const UPDATE_COUPON = "UPDATE_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const GET_COUPON_BY_ID = "GET_COUPON_BY_ID";
export const GET_ARTICLE_CATS = "GET_ARTICLE_CATS";
export const ADD_ARTICLE_CAT = "ADD_CPUPON";
export const UPDATE_ARTICLE_CAT = "UPDATE_ARTICLE_CAT";
export const DELETE_ARTICLE_CAT = "DELETE_ARTICLE_CAT";
export const GET_ARTICLE_CAT_BY_ID = "GET_ARTICLE_CAT_BY_ID";
export const SEARCH_FOR_PRODUCT = "SEARCH_FOR_PRODUCT";
export const GET_PRODUCTS_IN_MAIN_CATEGORY = "GET_PRODUCTS_IN_MAIN_CATEGORY";
export const GET_PRODUCTS_IN_SUB_CATEGORY = "GET_PRODUCTS_IN_SUB_CATEGORY";
export const GET_SUB_IN_MAIN = "GET_SUB_IN_MAIN";
export const GET_ARTICLES = "GET_ARTICLES";
export const GET_ARTICLE_BY_ID = "GET_ARTICLE_BY_ID";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const GET_PRODUCTS_DISCOUNT = "GET_PRODUCTS_DISCOUNT";
export const DELETE_PRODUCT_DISCOUNT = "DELETE_PRODUCT_DISCOUNT";
export const ADD_PRODUCT_DISCOUNT = "ADD_PRODUCT_DISCOUNT";
export const GET_SLIDERS = "GET_SLIDERS";
export const ADD_SLIDER = "ADD_SLIDER";
export const UPDATE_SLIDER = "UPDATE_SLIDER";
export const DELETE_SLIDER = "DELETE_SLIDER";
export const GET_SLIDER_BY_ID = "GET_SLIDER_BY_ID";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const GET_TERMS = "GET_TERMS";
export const DELETE_TERM = "DELETE_TERM";
export const ADD_TERM = "ADD_TERM";
export const GET_TERM_BY_ID = "GET_TERM_BY_ID";
export const UPDATE_TERM = "UPDATE_TERM";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const GET_ALL_PRO_DISCOUNT = "GET_ALL_PRO_DISCOUNT";
export const GET_INQUIRIES = "GET_INQUIRIES";
export const GET_INQUIRY_BY_ID = "GET_INQUIRY_BY_ID";
export const DELETE_INQUIRY = "DELETE_INQUIRY";
export const ANSWER_INQUIRY = "ANSWER_INQUIRY";
export const ADD_COMPETITION = "ADD_COMPETITION";
export const GET_COMPETITIONS = "GET_COMPETITIONS";
export const DELETE_COMPETITION = "DELETE_COMPETITION";
export const GET_COMPETITION_BY_ID = "GET_COMPETITION_BY_ID";
export const UPDATE_COMPETITION = "UPDATE_COMPETITION";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_NEWS_LETTER = "GET_NEWS_LETTER";
export const DELETE_NEWS_LETTER = "DELETE_NEWS_LETTER";
export const CAT_LOADING = "CAT_LOADING";
export const SUB_LOADING = "SUB_LOADING";
export const BRAND_LOADING = "BRAND_LOADING";
export const PRODUCT_LOADING = "PRODUCT_LOADING";
export const DISCOUNTS_LOADING = "DISCOUNTS_LOADING";
export const DISCOUNTS_PRODUCTS_LOADING = "DISCOUNTS_PRODUCTS_LOADING";
export const COUPONS_LOADING = "COUPONS_LOADING";
export const CITIES_LOADING = "CITIES_LOADING";
export const SLIDERS_LOADING = "SLIDERS_LOADING";
export const SHIPPERS_LOADING = "SHIPPERS_LOADING";
export const GET_MAIN_CATEGORY_PRODUCTS = "GET_MAIN_CATEGORY_PRODUCTS";
export const GET_SUB_CATEGORY_PRODUCTS = "GET_SUB_CATEGORY_PRODUCTS";
export const ARTICLE_CAT_LOADING = "ARTICLE_CAT_LOADING";
export const ARTICLE_LOADING = "ARTICLE_LOADING";
export const ORDERS_LOADING = "ORDERS_LOADING";
export const USERS_LOADING = "USERS_LOADING";
export const TERMS_LOADING = "TERMS_LOADING";
export const INQUIRIES_LOADING = "INQUIRIES_LOADING";
export const COMPETITIONS_LOADING = "COMPETITIONS_LOADING";
export const GET_CARTS = "GET_CARTS";
export const CARTS_LOADING = "CARTS_LOADING";
export const VERIFY = "VERIFY";
export const START_VERIFY = "START_VERIFY";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";
export const PASSWORD_CHANGE_FAIL = "PASSWORD_CHANGE_FAIL";
export const GET_SUPPORT = "GET_SUPPORT";
export const SUPPORT_LOADING = "SUPPORT_LOADING";
export const CHANGE_MOBILE = "CHANGE_MOBILE";
export const CHANGE_MOBILE_FAIL = "CHANGE_MOBILE_FAIL";
export const CHANGE_MOBILE_CONFIRM = "CHANGE_MOBILE_CONFIRM";
export const CHANGE_MOBILE_CONFIRM_FAIL = "CHANGE_MOBILE_CONFIRM_FAIL";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const CHANGE_EMAIL_FAIL = "CHANGE_EMAIL_FAIL";
export const CONFIRM_EMAIL_FAIL = "CONFIRM_EMAIL_FAIL";
export const GET_SIZES = "GET_SIZES";
export const ADD_AND_RETURN = "ADD_AND_RETURN";
export const ADD_AND_COINTINUE = "ADD_AND_CONTINUE";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const QUANTITY_LOADING = "QUANTITY_LOADING";
export const GET_CHECKOUT = "GET_CHECKOUT";
export const ADD_ORDER = "ADD_ORDER";
export const GET_OFFERS = "GET_OFFERS";
export const WISHLIST = "WISHLIST";
export const WISHLIST_LOADING = "WISHLIST_LOADING";
export const WISHLIST_DELETE = "WISHLIST_DELETE";
export const GET_SIZES_2 = "GET_SIZES_2";
export const GET_PRODUCT_BY_ID_2 = "GET_PRODUCT_BY_ID_2";
export const GET_REASONS = "GET_REASONS";
export const FILTER = "FILTER";
