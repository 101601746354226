import { combineReducers } from "redux";
import catReducer from "./catReducer";
import productsReducer from "./productsReducer";
import brandsReducer from "./brandsReducer";
import ordersReducer from "./orderdReducer";
import usersReducer from "./usersReducer";
import authReducer from "./authReucer";
import termsReducer from "./termsReducer";
import inquiriesReducer from "./inquiriesReducer";
import cartsReducer from "./cartsReducer";
import newsReducer from "./newsReducer";
import citiesReducer from "./citiesReducer";
import checkoutReducer from "./checkoutReducer";
import wishlistReducer from "./wishlistReducer";
import filterReducer from "./filterReducer";

export default combineReducers({
  categories: catReducer,
  products: productsReducer,
  brands: brandsReducer,
  orders: ordersReducer,
  users: usersReducer,
  login: authReducer,
  terms: termsReducer,
  inquiries: inquiriesReducer,
  carts: cartsReducer,
  news: newsReducer,
  cities: citiesReducer,
  checkout: checkoutReducer,
  wishlist: wishlistReducer,
  filter: filterReducer,
});
