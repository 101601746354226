import { GET_INQUIRIES, GET_INQUIRY_BY_ID, ANSWER_INQUIRY, INQUIRIES_LOADING } from '../actions/types'

const initialState = {
    inquiries: [],
    inquiry: [],
    updated: [],
    loading: true
}

const inquiriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INQUIRIES:
            return {
                ...state,
                inquiries: action.payload,
                loading: false
            };
        case GET_INQUIRY_BY_ID:
            return {
                ...state,
                inquiry: action.payload?.inquiry,
                loading: false
            };
        case ANSWER_INQUIRY:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case INQUIRIES_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default inquiriesReducer;