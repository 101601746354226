import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { AiFillHeart, AiOutlineHeart, AiOutlineShareAlt } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import errorImage from "../assets/errorimg.png";
import { wishListAddOrRemove } from "../redux/actions/cartsActions";
import { myToast } from "./Toast";

const ProductCard = ({ isDiscount, title, price, url, id, fav, code }) => {
  const [favorite, setFavorite] = React.useState(fav);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleImageError = (e) => {
    e.target.src = errorImage;
  };
  const handleFav = () => {
    dispatch(wishListAddOrRemove(id));
    setFavorite(!favorite);
  };
  console.log("THE CODE", code?.substring(0, 2));
  const theCode = code?.substring(0, 2);
  return (
    <div className="product-card">
      {isDiscount || theCode === "e0" ? <span className="exclisuve-offer">عرض خاص</span> : null}
      {favorite ? (
        <AiFillHeart onClick={handleFav} className="add-to-fav-butt fav-outside" />
      ) : (
        <AiOutlineHeart onClick={handleFav} className="add-to-fav-butt fav-outside" />
      )}
      <CopyToClipboard text={`https://bluepointwear.com/view-product/${id}`}>
        <AiOutlineShareAlt onClick={() => myToast("تم نسخ رابط المنتج")} className="add-to-fav-butt share-outside" />
      </CopyToClipboard>
      <div className="flex-center product-image-wrapper">
        <img
          onClick={() => history.push(`/view-product/${id}`)}
          onError={handleImageError}
          alt="kids"
          src={url}
          className="sub-cat-card-image"
        />
      </div>
      <div onClick={() => history.push(`/view-product/${id}`)} className="product-card-data">
        <div>
          <p className="text-center mt-2 mb-0 produce-title sub-cat-title">{title}</p>
          <p className="text-center mt-0 sub-cat-title product-price">السعر حسب المقاس</p>
          <div className="flex-center">
            <button className="login-button view-product-button">عرض المنتج</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
