import 'bootstrap/dist/css/bootstrap.min.css';
import AuthRouter from './AuthRouter';
import UnAuthRouter from './UnAuthRouter'
import { useSelector } from 'react-redux';
import './index.css';
import { ToastContainer } from 'react-toastify';

function Auth() {
    const logged = useSelector(state => state.login.logged)
    return (
            <div>
                <ToastContainer rtl={true} />
                {
                    localStorage.getItem('auth') === 'true' || logged === true || sessionStorage.getItem('auth') === true ?
                        <AuthRouter /> :
                        <UnAuthRouter />
                }
            </div>
    );
}

export default Auth;
