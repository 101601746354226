import React from "react";
import { useDispatch, useSelector } from "react-redux";
import NewCard from "../components/NewCard";
import AppSpinner from "../components/Spinner/Spinner";
import { setLoading } from "../redux/actions/loading";
import { getNews } from "../redux/actions/newsActions";

const News = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setLoading());
    dispatch(getNews());
  }, [dispatch]);
  React.useState(() => {
    document.title = "Blue Point | الاخبار";
  }, []);
  const user = useSelector((state) => state.users.user);
  const news = useSelector((state) => state.news.news?.blogs);
  return news ? (
    <div className="container">
      <h1 className="school-name">{user?.category?.name}</h1>

      <div className="row ">
        {news?.length === 0 ? (
          <h2 className="bold mt-5 mb-5 p-3 text-center">
            عفواً, لا توجد اخبار جديدة
          </h2>
        ) : (
          news?.map((row, index) => {
            return (
              <div
                key={index}
                className="col-lg-4 col-md-4 col-sm-12 flex-center"
              >
                <NewCard
                  date={row.created_at}
                  id={row.id}
                  title={row.title}
                  price={row.price}
                  url={row.photo_thumbnail_path}
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  ) : (
    <AppSpinner />
  );
};

export default News;
