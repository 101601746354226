import axios from "axios";
import React from "react";

function Wallet() {
  const [credit, setCredit] = React.useState(null);
  const getData = () => {
    axios
      .get(`https://bluepointwear.com/backend/ar/api/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => setCredit(res.data?.user?.customer_wallet));
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <h2 className="text-center bold">محفظتي</h2>
      <div className="flex-center">
        <img style={{ height: "300px" }} src="wallet.png" alt="wallet" />
      </div>
      <h2 className="text-center mt-4 mb-4">الرصيد: {credit} جنيه</h2>
    </div>
  );
}

export default Wallet;
