import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AppFooter from "./components/UnAuthFooter";
import CompleteRegistration from "./pages/CompleteRegistration";
import ForgetPassword from "./pages/ForgetPassword";
import ChangePassword from "./pages/ForgetPasswordChange";
import LoginPage from "./pages/LoginPage";
import VerifyAccount from "./pages/VerifyAccount";
import { useDispatch } from "react-redux";
import { getTerms } from "./redux/actions/termsActions";

const UnAuthRouter = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getTerms());
  }, [dispatch]);
  return (
    <>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/register" component={CompleteRegistration} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Route exact path="/verify/:phoneNumber" component={VerifyAccount} />
        <Route exact path="confirm-profile" component={VerifyAccount} />
        <Route exact path="/change-password/:mobile" component={ChangePassword} />
        <Route
          exact
          path="/complete-registration/:firstName/:secondName/:thirdName/:fourthName/:phoneNumber"
          component={CompleteRegistration}
        />
        <Route render={() => <Redirect to={{ pathname: "/" }} />} />
      </Switch>
      <AppFooter />
    </>
  );
};

export default UnAuthRouter;
