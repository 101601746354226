import axios from "axios";
import { myToast, myError } from "../../components/Toast";
import * as types from "./types";
const lang = localStorage.getItem("i18nextLng");

export const getProducts = (categoryId) => (dispatch) => {
  axios
    .get(`https://bluepointwear.com/backend/ar/api/products/${categoryId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.PRODUCT_LOADING,
        payload: res.data,
      });
      dispatch({
        type: types.GET_PRODUCTS,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error?.response?.message));
};

export const getOffers = () => (dispatch) => {
  axios
    .get(`https://bluepointwear.com/backend/ar/api/offers`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_OFFERS,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error?.response?.message));
};

export const getSizes = (productId, colorId) => (dispatch) => {
  axios
    .get(`https://bluepointwear.com/backend/ar/api/get-sizes/${productId}?color_id=${colorId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_SIZES,
        payload: res.data,
      });
    });
};

export const getProductById = (id, setLoading) => (dispatch) => {
  axios
    .get(`https://bluepointwear.com/backend/ar/api/product/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.GET_PRODUCT_BY_ID,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error?.response?.message))
    .finally(() => setLoading(false));
};

export const getFeatured = (currentPage, limit) => (dispatch) => {
  axios
    .get(`https://matjrna.ml/api/v1/product/get-featured/?page=${currentPage}&limit=${limit}`)
    .then((res) => {
      dispatch({
        type: types.GET_PRODUCTS,
        payload: res.data,
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const searchForProducts = (searchContent, currentPage) => (dispatch) => {
  axios
    .post(`https://matjrna.ml/api/v1/product/search?page=${currentPage}`, searchContent)
    .then((res) => {
      dispatch({
        type: types.GET_PRODUCTS,
        payload: res.data,
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getSellCodeProducts = (sellCode, currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.ml/api/v1/product/get-product-by-salecode-distinct/${sellCode}?page=${currentPage}`)
    .then((res) => {
      dispatch({
        type: types.PRODUCT_LOADING,
        payload: res.data,
      });
      dispatch({
        type: types.GET_PRODUCTS,
        payload: res.data,
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getMainCategoryProducts = (categoryId, currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.ml/api/v1/category/main/${categoryId}/products?page=${currentPage}`)
    .then((res) => {
      dispatch({
        type: types.PRODUCT_LOADING,
        payload: res.data,
      });
      dispatch({
        type: types.GET_MAIN_CATEGORY_PRODUCTS,
        payload: res.data,
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const getSubCategoryProducts = (categoryId, currentPage) => (dispatch) => {
  axios
    .get(`https://matjrna.ml/api/v1/category/sub/${categoryId}/products?page=${currentPage}`)
    .then((res) => {
      dispatch({
        type: types.PRODUCT_LOADING,
        payload: res.data,
      });
      dispatch({
        type: types.GET_SUB_CATEGORY_PRODUCTS,
        payload: res.data,
      });
    })
    .catch((error) => myError(error?.response?.message));
};

export const addProduct =
  (
    nameEn,
    nameAr,
    mainCategory,
    subCategory,
    descriptionAr,
    descriptionEn,
    brand,
    price,
    color,
    size,
    stocks,
    image,
    images,
    sellCode,
    video,
    tags
  ) =>
  (dispatch) => {
    const inputsData = new FormData();
    inputsData.append("nameEn", nameEn);
    inputsData.append("nameAr", nameAr);
    inputsData.append("mainCategory", mainCategory);
    inputsData.append("subCategory", subCategory);
    inputsData.append("descriptionAr", descriptionAr);
    inputsData.append("descriptionEn", descriptionEn);
    inputsData.append("brand", brand);
    inputsData.append("price", price);
    inputsData.append("color", color);
    inputsData.append("size", size);
    inputsData.append("saleCode", sellCode);
    inputsData.append("stocks", stocks);
    inputsData.append("video", video);
    inputsData.append("imageCover", image);
    images?.map((image) => inputsData.append("images", image));
    tags?.map((tag) => inputsData.append("tags", tag));
    axios
      .post(`https://matjrna.ml/api/v1/product`, inputsData)
      .then((res) => {
        dispatch({
          type: types.ADD_PRODUCTS,
          payload: res,
        });
        myToast(lang === "ar" ? "تم إضافة منتج جديد" : "New product has been added");
      })
      .catch((error) => myError(error?.response?.message));
  };

export const editProduct =
  (
    id,
    nameEn,
    nameAr,
    mainCategory,
    subCategory,
    descriptionAr,
    descriptionEn,
    brand,
    price,
    color,
    size,
    stocks,
    image,
    images,
    sellCode,
    video,
    oldImage,
    tags,
    active
  ) =>
  (dispatch) => {
    const inputsData = new FormData();
    inputsData.append("nameEn", nameEn);
    inputsData.append("nameAr", nameAr);
    inputsData.append("mainCategory", mainCategory);
    inputsData.append("subCategory", subCategory);
    inputsData.append("descriptionAr", descriptionAr);
    inputsData.append("descriptionEn", descriptionEn);
    inputsData.append("brand", brand);
    inputsData.append("price", price);
    inputsData.append("color", color);
    inputsData.append("size", size);
    inputsData.append("saleCode", sellCode);
    inputsData.append("stocks", stocks);
    inputsData.append("video", video);
    inputsData.append("imageCover", image);
    inputsData.append("old_imageCover", oldImage);
    inputsData.append("active", active);
    tags?.map((tag) => inputsData.append("tags", tag));
    images?.map((image) => inputsData.append("images", image));
    axios
      .patch(`https://matjrna.ml/api/v1/product/${id}`, inputsData)
      .then((res) => {
        dispatch({
          type: types.ADD_PRODUCTS,
          payload: res,
        });
        myToast(lang === "ar" ? "تم تعديل المنتج" : "Product has been edited");
      })
      .catch((error) => myError(error?.response?.message));
  };

export const deleteProduct = (id) => (dispatch) => {
  axios
    .delete(`https://matjrna.ml/api/v1/product/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_PRODUCTS,
        payload: res,
      });
      myToast(lang === "ar" ? "تم حذف المنتج" : "Product has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};

export const setBestSeller = (id) => (dispatch) => {
  axios
    .patch(`https://matjrna.ml/api/v1/product/toggle-bestsellers/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_PRODUCTS,
        payload: res,
      });
      myToast(res.data.message);
    })
    .catch((error) => myError(error?.response?.message));
};

export const setFeatured = (id) => (dispatch) => {
  axios
    .patch(`https://matjrna.ml/api/v1/product/toggle-featured/${id}`)
    .then((res) => {
      dispatch({
        type: types.ADD_PRODUCTS,
        payload: res,
      });
      myToast(res.data.message);
    })
    .catch((error) => myError(error?.response?.message));
};

export const deleteImage = (data) => (dispatch) => {
  axios
    .post(`https://matjrna.ml/api/v1/product/delete-image/${data.proId}`, { imageName: data.image })
    .then((res) => {
      dispatch({
        type: types.ADD_PRODUCTS,
        payload: res,
      });
      myToast(lang === "ar" ? "تم حذف الصورة" : "Image has been deleted");
    })
    .catch((error) => myError(error?.response?.message));
};

export const setLoading = () => {
  return {
    type: types.LOADING,
  };
};
