import React from 'react';

const ReturnProducts = ({orderId, setBalanceData, product, data, setData, setShow }) => {
   


    return (
        <div>
            <h5 className='text-center p-5'>الإسترجاع يكون عن طريق المدرسة فقط</h5>
        </div>
    )
}

export default ReturnProducts;