import axios from "axios";
import { myToast } from "../../components/Toast";
import * as types from "./types";

export const getUserById = () => (dispatch) => {
  axios
    .get(`https://bluepointwear.com/backend/ar/api/profile`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: types.USERS_LOADING,
        payload: res.data,
      });
      dispatch({
        type: types.GET_USER_BY_ID,
        payload: res.data,
      });
    })
    .catch((error) => {
      if (error?.response?.data?.message === "Unauthenticated.") {
        window.location.reload();
        localStorage.clear();
      }
    });
};
//
export const changePassword = (setLoading, setRedirect, data) => (dispatch) => {
  setLoading(true);
  axios
    .post(`https://bluepointwear.com/backend/ar/api/profile/change-password`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      setLoading(false);
      setRedirect(true);
      myToast("تم تغيير كلمة السر بنجاح");
    })
    .catch((err) => {
      setLoading(false);
      dispatch({
        type: types.PASSWORD_CHANGE_FAIL,
        payload: err,
      });
    });
};

export const changeMobile = (data, setRedirect, setLoading) => (dispatch) => {
  setLoading(true);
  axios
    .post(`https://bluepointwear.com/backend/ar/api/profile/change/mobile`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      setLoading(false);
      setRedirect(true);
      // alert(res.data?.msg)
    })
    .catch((err) => {
      setLoading(false);
      dispatch({
        type: types.CHANGE_MOBILE_FAIL,
        payload: err,
      });
    });
};

export const confirmMobile = (data, setRedirect, setLoading) => (dispatch) => {
  setLoading(true);
  axios
    .post(`https://bluepointwear.com/backend/ar/api/profile/confirm/mobile`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      setLoading(false);
      setRedirect(true);
      myToast("تم تغيير الرقم بنجاح");
    })
    .catch((err) => {
      setLoading(false);
      dispatch({
        type: types.CHANGE_MOBILE_CONFIRM_FAIL,
        payload: err,
      });
    });
};

export const changeEmail = (data, setRedirect, setLoading) => (dispatch) => {
  setLoading(true);
  axios
    .post(`https://bluepointwear.com/backend/ar/api/profile/change/email`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      setLoading(false);
      setRedirect(true);
      // alert(res.data?.msg)
    })
    .catch((err) => {
      setLoading(false);
      dispatch({
        type: types.CHANGE_EMAIL_FAIL,
        payload: err,
      });
    });
};

export const confirmEmail = (data, setRedirect, setLoading) => (dispatch) => {
  setLoading(true);
  axios
    .post(`https://bluepointwear.com/backend/ar/api/profile/confirm/email`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      setLoading(false);
      setRedirect(true);
      myToast("تم تغيير البريد الإلكتروني بنجاح");
    })
    .catch((err) => {
      setLoading(false);
      dispatch({
        type: types.CONFIRM_EMAIL_FAIL,
        payload: err,
      });
    });
};

export const updateUser = (data, reload, getProfile, redirect) => (dispatch) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("mobile", data.mobile);
  formData.append("phone", data.phone === null ? "" : data.phone);
  formData.append("email", data.email);
  formData.append("age", data.age);
  formData.append("gender", data.gender);
  formData.append("address[gov]", data.mohafza);
  formData.append("address[city]", data.city);
  formData.append("address[near]", data.near);
  formData.append("address[street]", data.street);
  formData.append("address[area]", data.district);
  formData.append("address[home_number]", data.building);
  formData.append("address[apartment]", data.home);
  formData.append("address[floor]", data.floor);
  formData.append("address[from]", data.startTime);
  formData.append("address[to]", data.endTime);
  formData.append("category_id", data.category);
  axios
    .post(`https://bluepointwear.com/backend/ar/api/profile`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      getProfile();
      setTimeout(() => {
        redirect();
      }, 2500);
      dispatch({
        type: types.UPDATE_ADMIN,
        payload: res.data,
      });
      setTimeout(() => {
        if (reload) {
          myToast("تم تعديل البيانات بنجاح");
        }
      }, [20]);
    })
    .catch((err) => {
      dispatch({
        type: types.DELETE_USER,
        payload: err,
      });
    });
};
