import React from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import logo from '../assets/logo.png';
import { verifyCode } from "../redux/actions/authActions";
import { useDispatch, useSelector } from 'react-redux'
import { BiLoaderAlt } from "react-icons/bi";
import { setLoading } from "../redux/actions/loading";

const VerifyAccount = () => {
    const { phoneNumber } = useParams();
    const [code, setCode] = React.useState(1);
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector(state => state?.login?.loading);
    const [redirect, setRedirect] = React.useState(false);
    const redirection = () => {
        history.push('/confirm-profile')
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLoading())
        dispatch(verifyCode({ verification_code: code, mobile: phoneNumber }, redirection))
    }

    React.useEffect(() => {
        if (redirect) {
            history.push('/confirm-profile')
        }
    }, [redirect, history])


    return (
        <div className="container">
            <div className='flex-center login-logo-wrapper'>
                <img className="logo-limits" alt='logo' src={logo} />
            </div>

            <div>
                <h4 className='text-center login-word mb-3'>تفعيل الحساب</h4>
            </div>

            <p className='text-color bold text-center'>برجاء إدخال الرمز المرسل إليكم عبر الهاتف</p>


            <div className='flex-center'>
                <form onSubmit={handleSubmit} className='register-form'>

                    <label>رقم الهاتف</label>
                    <input required defaultValue={phoneNumber} disabled className='form-control app-input' />

                    <label>رمز التفعيل</label>
                    <input name='codeEE' onChange={(e) => setCode(e.target.value)} type='number' required className='form-control app-input' placeholder="رمز التفعيل" />

                    <button className='login-button fixed-login mb-3'>
                        {loading ? <BiLoaderAlt className="login-loader" /> : 'تفعيل الحساب'}
                    </button>
                </form>
            </div>

        </div>
    )
}

export default VerifyAccount;