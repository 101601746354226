import React from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { changeEmail } from '../redux/actions/usersActions';

const ChangeEmail = () => {
    const [email, setEmail] = React.useState();
    const changeError = useSelector(state => state?.users?.changeEmailError);
    const [redirect, setRedirect] = React.useState(false)
    const history = useHistory();
    const [loading, setLoading] = React.useState(false)
    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(changeEmail({ email }, setRedirect, setLoading))
    }

    React.useEffect(() => {
        if (redirect) {
            history.push('/confirm-email')
        }
    }, [redirect, history])


    return (
        <div className='container'>
            <div className='flex-center'>
            </div>
            <h2 className='text-center bold'>حسابي</h2>
            <h4 className='text-center bold mb-4'>تغيير البريد الإلكتروني</h4>
            <div className='row update-info-container'>
                <form onSubmit={handleSubmit}>
                    <div className='row mt-3'>
                        <label>البريد الإلكتروني الجديد</label>
                        <input onChange={(e) => setEmail(e.target.value)} placeholder='ادخل البريد الالكتروني الجديد' className='form-control app-input' />
                        <button className='login-button profile-buttons'>{loading ? <BiLoaderAlt className='login-loader' /> : "ارسال رمز التأكيد"}</button>
                        <p className='login-error'>{changeError}</p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChangeEmail;