import React from "react";
import { Link } from "react-router-dom";
import logo from '../assets/logo.png'
import { forgetPassword } from "../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setLoading } from "../redux/actions/loading";
import { BiLoaderAlt } from "react-icons/bi";
import parse from 'html-react-parser'

const ForgetPassword = () => {
    const [mobile, setMobile] = React.useState(1);
    const loading = useSelector(state => state.login.loading)
    const move = useSelector(state => state.login.forgetSent)
    const dispatch = useDispatch();
    const history = useHistory();
    const errors = useSelector(state => state.login.forgetErrors);
    const text = useSelector(state => state.terms?.terms?.setting?.login_page)

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLoading())
        dispatch(forgetPassword({ mobile }))
    }

    if (move) {
        history.push(`/change-password/${mobile}`)
    }
    return (
        <div>
            <div className='flex-center login-logo-wrapper'>
                <img className="logo-limits" alt='logo' src={logo} />
            </div>

            <div className='row flex-center login-row'>
                <div className='col-12 flex-center'>
                    <form onSubmit={handleSubmit}>
                        <h4 className='text-center login-word mb-4'>تغير كلمة المرور</h4>
                        <p className='text-color mb-1'>الرجاء إدخال رقم الهاتف لتلقى رمز التحقق.</p>

                        <label>رقم الهاتف</label>
                        <input type='number' onChange={(e) => setMobile(e.target.value)} className='form-control app-input' placeholder='رقم الهاتف' />
                        <p className="register-error">{errors?.mobile ? mobile?.length === 11 ? 'الرقم غير متصل بحساب' : "رقم الهاتف مكون من 11 رقم" : null}</p>

                        <button style={{ width: '70px' }} className='login-button mt-3'>{
                            loading ? <BiLoaderAlt className="login-loader" /> : 'تأكيد'
                        }</button>
                        <p className='mt-2 text-color'>إذا كان لديك حساب معنا ، يرجى تسجيل الدخول.</p>
                        <Link style={{ textDecoration: 'none' }} to='/'>
                            <p className='forget-password'>تسجيل الدخول</p>
                        </Link>
                    </form>
                </div>



                <div className='mt-3'>
                    {
                        text?.length > 3 ?
                            parse(text) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword;