import { GET_CATEGORY_BY_ID, ADD_CAT, CAT_LOADING, GET_CHECKOUT, ADD_ORDER, GET_REASONS } from '../actions/types'

const initialState = {
    checkout: [],
    category: [],
    updated: [],
    loading: true,
    reasons: []
}

const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CHECKOUT:
            return {
                ...state,
                checkout: action.payload,
                loading: false
            };
        case GET_REASONS:
            return {
                ...state,
                reasons: action.payload,
                loading: false
            };
        case ADD_ORDER:
            return {
                ...state,
                checkout: action.payload,
                loading: false
            };
        case GET_CATEGORY_BY_ID:
            return {
                ...state,
                category: action.payload?.data?.category,
                loading: false
            };
        case ADD_CAT:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case CAT_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default checkoutReducer;