import React from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const PasswordInput = ({onChange, placeholder}) => {
    const [showPassword, setShowPassword] = React.useState(false);
    return(
        <div className='flex-center'>
            <input type={showPassword ? 'text' : 'password'} required onChange={onChange} className='form-control app-input' placeholder={placeholder} />
            {
                showPassword?
                    <AiOutlineEyeInvisible onClick={() => setShowPassword(!showPassword)} size={20} style={{ marginRight: '-25px', marginBottom: '7px', cursor: 'pointer' }} /> :
                    <AiOutlineEye onClick={() => setShowPassword(!showPassword)} size={20} style={{ marginRight: '-25px', marginBottom: '7px', cursor: 'pointer' }} />
            }
        </div>
    )
}

export default PasswordInput;