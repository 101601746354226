import axios from 'axios';
import { myToast, myError } from '../../components/Toast';
import * as types from './types';
const lang = localStorage.getItem('i18nextLng')

export const getCheckout = () => dispatch => {
    axios.get(`https://bluepointwear.com/backend/ar/api/checkout`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => {
            dispatch({
                type: types.GET_CHECKOUT,
                payload: res.data
            })
        })
        .catch(error => myError(error?.response?.message))
}

export const getReasons = () => dispatch => {
    axios.get(`https://bluepointwear.com/backend/ar/api/reason-change`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => {
            dispatch({
                type: types.GET_REASONS,
                payload: res.data
            })
        })
        .catch(error => console.log(error?.response?.message))
}

export const addOrder = (payment, data, code) => dispatch => {
    const homeData = new FormData()
    homeData.append('payment_id', payment)
    homeData.append('shipping_id', data.shippingWay)
    homeData.append('address[gov]', data.mohafza)
    homeData.append('address[city]', data.city)
    homeData.append('address[near]', data.near)
    homeData.append('address[street]', data.street)
    homeData.append('address[area]', data.district)
    homeData.append('address[home_number]', data.building)
    homeData.append('address[apartment]', data.home)
    homeData.append('address[floor]', data.floor)
    homeData.append('address[from]', data.startTime)
    homeData.append('address[to]', data.endTime)
    homeData.append('code', code)
    const companyData = new FormData();
    companyData.append('branch_id', data.branchId)
    companyData.append('payment_id', payment)
    companyData.append('shipping_id', data.shippingWay)
    companyData.append('code', code)
    const schoolData = new FormData();
    schoolData.append('payment_id', payment)
    schoolData.append('shipping_id', data.shippingWay)
    schoolData.append('code', code)
    axios.post(`https://bluepointwear.com/backend/ar/api/store-order`, data.shippingWay === '1' ? homeData : data.shippingWay === '2' ? companyData : schoolData, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(res => {
            dispatch({
                type: types.ADD_ORDER,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const editCat = (id, nameEn, nameAr, type, image, oldImage) => dispatch => {
    const inputsData = new FormData();
    inputsData.append('nameEn', nameEn)
    inputsData.append('nameAr', nameAr)
    inputsData.append('type', type)
    inputsData.append('image', image)
    inputsData.append('old_image', oldImage)
    axios.patch(`https://matjrna.ml/api/v1/category/${id}`, inputsData)
        .then(res => {
            dispatch({
                type: types.ADD_CAT,
                payload: res
            })
            myToast(lang === 'ar' ? 'تم تعديل القسم' : 'Category has been edited')
        })
        .catch(error => myError(error?.response?.message))
}

export const deleteCat = (id) => dispatch => {
    axios.delete(`https://matjrna.ml/api/v1/category/${id}`)
        .then(res => {
            dispatch({
                type: types.ADD_CAT,
                payload: res
            })
            myToast(lang === 'ar' ? 'تم حذف القسم' : 'Category has been deleted')
        })
        .catch(error => myError(error?.response?.message))
}