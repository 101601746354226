import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import AppFooter from "./components/Footer";
import AppNavbar from "./components/Navbar";
import AppSpinner from "./components/Spinner/Spinner";
import WishlistContent from "./components/WishlistContent";
import AboutUs from "./pages/AboutUs";
import ChangeEmail from "./pages/ChangeEmail";
import ChangeMobileConfirmation from "./pages/ChangeMobileConfirmation";
import ChangePassword from "./pages/ChangePassword";
import ChangeMobile from "./pages/ChangePhoneNumber";
import Checkout from "./pages/Checkout";
import ConfirmEmail from "./pages/ConfirmEmail";
import Inquiries from "./pages/Inquiries";
import MyProfile from "./pages/MyProfile";
import News from "./pages/News";
import Products from "./pages/Products";
import ShoppingCart from "./pages/ShoppingCart";
import Support from "./pages/Support";
import Terms from "./pages/Terms";
import ViewNew from "./pages/ViewNew";
import ViewProduct from "./pages/ViewProduct";
import { getCats } from "./redux/actions/catActions";
import { getCities } from "./redux/actions/citiesActions";
import { getUserById } from "./redux/actions/usersActions";
import { useLocation } from "react-router-dom";
import { getCart } from "./redux/actions/cartsActions";
import Orders from "./pages/Orders";
import ViewOrder from "./pages/ViewOrder";
import Offers from "./pages/Offers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PaymentSuccess from "./pages/PaymentSuccess";
import Wallet from "./pages/Wallet";
import ShopProducts from "./pages/ShopProducts";
import MyProfileBranch from "./pages/MyProfileBranch";
import YourInvoice from "./pages/YourInvoice";
import Refunds from "./pages/Refunds";
import ViewRefund from "./pages/ViewRefund";

const AuthRouter = () => {
  const dispatch = useDispatch();
  const [move, setMove] = React.useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const [rendered, setRendered] = React.useState(false);
  const user = useSelector((state) => state.users.user);
  const token = localStorage.getItem("token");
  React.useEffect(() => {
    dispatch(getUserById(token));
    dispatch(getCats());
    dispatch(getCities());
    dispatch(getCart());
  }, [dispatch]);

  setTimeout(() => {
    setMove(true);
  }, 1800);

  setTimeout(() => {
    setRendered(true);
  }, [1000]);

  React.useEffect(() => {
    if (rendered) {
      if (user?.establishment !== "e14") {
        if (user?.age === null) {
          history.push("/my-profile");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, pathname, move]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const loading = useSelector((state) => state.users.loading);
  return loading ? (
    <div className="flex-center center-spinner">
      <AppSpinner />
    </div>
  ) : (
    <>
      <div className="hide-print">
        <AppNavbar />
      </div>
      <Switch>
        <Route exact path="/" component={user?.establishment === "e0" ? Offers : user?.establishment === "e14" ? ShopProducts : Products} />
        <Route exact path="/verify/:phoneNumber" component={Products} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/view-product/:productId" component={ViewProduct} />
        <Route exact path="/cart" component={ShoppingCart} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/inquiries" component={Inquiries} />
        <Route exact path="/my-profile" component={user?.establishment === "e14" ? MyProfileBranch : MyProfile} />
        <Route exact path="/news" component={News} />
        <Route exact path="/view-new/:newId" component={ViewNew} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/change-mobile" component={ChangeMobile} />
        <Route exact path="/wallet" component={Wallet} />
        <Route exact path="/change-mobile/confirmation" component={ChangeMobileConfirmation} />
        <Route exact path="/change-email" component={ChangeEmail} />
        <Route exact path="/confirm-email" component={ConfirmEmail} />
        <Route exact path="/wishlist" component={WishlistContent} />
        <Route exact path="/about" component={AboutUs} />
        <Route exact path="/orders" component={Orders} />
        <Route exact path="/offers" component={Offers} />
        <Route exact path="/view-order/:orderId" component={user?.establishment === "e14" ? YourInvoice : ViewOrder} />
        <Route exact path="/payment-success" component={PaymentSuccess} />
        <Route exact path="/refunds" component={Refunds} />
        <Route exact path="/view-refund/:id" component={ViewRefund} />
        <Route render={() => <Redirect to={{ pathname: "/" }} />} />
      </Switch>
      <div className="hide-print">
        <AppFooter auth />
      </div>
    </>
  );
};

export default AuthRouter;
