import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();


export function myToast(text) {
    toast(<span className='notification-text'>{text}</span>, {
        position: toast.POSITION.TOP_CENTER,
        progressStyle: { color: "#3ca556", background: "#3ca556" }
    });
}

export function bigToast(text) {
    toast(<span className='notification-text' style={{ color: '#fff', textAlign: 'center', zoom: '75%' }}>{text}</span>, {
        position: toast.POSITION.TOP_CENTER,
        progressStyle: { color: "#fff", background: "#fff" },
        style: { background: '#1f61aa', color: 'white', zoom: '150%', display: 'flex' }
    });
}


export function myError(text) {
    toast(<span className='notification-text'>{text}</span>, {
        position: toast.POSITION.BOTTOM_LEFT,
        progressStyle: { color: "red", background: "red" },
    });
}