/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import logo from "../assets/logo.png";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AppSpinner from "../components/Spinner/Spinner";
import ExchangeProducts from "../components/ExchangeProducts";
import { FaExchangeAlt } from "react-icons/fa";

function YourInvoice() {
  const [order, setOrder] = React.useState();
  const quantitiesArray = order?.products?.map((item) => item?.sold_quantity);
  const totalQuantity = quantitiesArray?.reduce((total, current) => total + current, 0);
  const [invoice, setInvoice] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { orderId } = useParams();
  const getOrders = () => {
    setLoading(true);
    axios
      .get(`https://bluepointwear.com/backend/ar/api/order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setOrder(res.data?.order);
      })
      .catch((err) => console.log(err));
  };

  const couponDiscount = order?.coupon_discount;
  const totalPrice = order?.total_price;
  const priceBeforeDiscount = totalPrice / (1 - couponDiscount / 100);
  const couponAmount = priceBeforeDiscount - totalPrice;
  console.log("orderorder", order);
  React.useEffect(() => {
    getOrders();
  }, []);

  const [showExchange, setShowExchange] = React.useState(false);

  const ExchangeButton = () => {
    return order?.changes[0]?.modify ? (
      <button onClick={() => setShowExchange(!showExchange)} className="login-button exchange-button">
        تعديل الإستبدال <FaExchangeAlt />
      </button>
    ) : null;
  };
  return loading ? (
    <AppSpinner />
  ) : (
    <div className={`container ${invoice ? "invoice" : ""}`}>
      <div className="branch-invoice mb-4">
        <button
          onClick={() => {
            setInvoice(false);
            setTimeout(() => {
              window.print();
            }, 100);
          }}
          className="login-button hide-print mb-3"
        >
          اطبع الفاتورة
        </button>
        <button
          style={{ marginRight: "20px" }}
          onClick={() => {
            setInvoice(true);
            setTimeout(() => {
              window.print();
            }, 100);
          }}
          className="login-button hide-print mb-3"
        >
          اطبع الإصال
        </button>
        <div className="flex-between">
          <div>
            <div className="invoice-logo">
              <img src={logo} alt="" />
              <h3>مصنع بلوبوينت للملابس الجاهزة</h3>
            </div>
            <h4>
              فاتورة مبيعات : {order?.invoices?.branch_id}/{order?.invoices?.branch_invoice}
            </h4>
            {/* <h4>الفرع : {order?.invoices?.branch_id}</h4> */}
            {/* <h4>رقم الطلب : 7371</h4> */}
            <h4>سجل تجارى : 325698 استثمار</h4>
            <h4>رقم التسجيل الضريبي : 205-046-037</h4>
            <h4>رقم البطاقه الضريبية : 205-046-037</h4>
            <h4>اسم العميل : عميل نقدي</h4>
            <h4>تاريخ الفاتورة : {new Date(order?.created_at).toLocaleString("en-GB")}</h4>
            {Boolean(order?.description) && <h4 className="hide-print">ملاحظات : {order?.description}</h4>}
          </div>
          <div className="large-invoice-logo">
            <img src={logo} alt="" />
            <h3>مصنع بلوبوينت للملابس الجاهزة</h3>
          </div>
        </div>
        <table className="table-responsive table table-hover mt-5 mb-5">
          <thead>
            <tr>
              <th scope="col">المنتج</th>
              <th scope="col">اللون</th>
              <th scope="col">المقاس</th>
              <th scope="col">سعر القطعة</th>
              <th scope="col">الكمية</th>
              <th scope="col">قيمة الطلب</th>
            </tr>
          </thead>
          <tbody>
            {order?.products?.map((product) => {
              const oldPrice = product?.old_price_pice * product?.sold_quantity;
              const isDiscount = Number(product?.price_pice) < product?.old_price_pice;
              return (
                <tr key={product.id}>
                  <td style={{ maxWidth: "180px" }}>{product?.product?.title}</td>
                  <td>{product?.color?.name}</td>
                  <td>{product?.size?.name}</td>
                  <td>
                    {" "}
                    {product?.price_pice} جنيه
                    {isDiscount && <span className="invoice-discount"> {product?.old_price_pice} جنيه</span>}{" "}
                  </td>
                  <td>{product?.sold_quantity}</td>
                  <td>
                    {product?.price} جنيه {isDiscount && <span className="invoice-discount"> {oldPrice} جنيه</span>}{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <h4> عدد القطع : {totalQuantity}</h4>
        <div className="products-invoice">
          <p className="grid-item fw-bold">المنتج</p>
          <p className="grid-item fw-bold">اللون</p>
          <p className="grid-item fw-bold">المقاس</p>
          <p className="grid-item fw-bold">سعر القطعة</p>
          <p className="grid-item fw-bold">الكمية</p>
          <p className="grid-item fw-bold">قيمة الطلب </p>
          {order?.products?.map((product) => {
            const oldPrice = product?.old_price_pice * product?.sold_quantity;
            const isDiscount = Number(product?.price_pice) < product?.old_price_pice;
            return (
              <>
                <p className="grid-item">{product?.product?.title}</p>
                <p className="grid-item">{product?.color?.name}</p>
                <p className="grid-item">{product?.size?.name}</p>
                <p className="grid-item">
                  {" "}
                  {product?.price_pice} جنيه
                  {isDiscount && <span className="invoice-discount"> {product?.old_price_pice} جنيه</span>}{" "}
                </p>
                <p className="grid-item">{product?.sold_quantity}</p>
                <p className="grid-item">
                  {product?.price} جنيه {isDiscount && <span className="invoice-discount"> {oldPrice} جنيه</span>}{" "}
                </p>
              </>
            );
          })}
        </div>
        {Boolean(couponDiscount) && <h4> اجمالي الفاتورة قبل خصم الكوبون : {priceBeforeDiscount?.toFixed(2)} جنيه</h4>}
        {Boolean(couponDiscount) && (
          <h4>
            {" "}
            خصم الكوبون : {couponDiscount} % ({couponAmount?.toFixed(2)} جنيه)
          </h4>
        )}
        <h4 className="mb-5 mt-5"> صافي الفاتورة : {totalPrice} جنيه</h4>
        <h4 className="text-center includes mb-4">الاسعار شامله ضريبة القيمة المضافة</h4>

        <button onClick={() => setShowExchange(!showExchange)} className="login-button exchange-button">
          استراع منتجات <FaExchangeAlt />
        </button>

        {showExchange && (
          <ExchangeProducts
            ExchangeButton={ExchangeButton}
            getOrder={getOrders}
            order={order}
            paymentId={order?.payment?.id}
            setShowDown={setShowExchange}
            orderId={order?.id}
            products={order?.products}
            fromShop
          />
        )}
      </div>
    </div>
  );
}

export default YourInvoice;
