import axios from "axios";
import React from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { myToast } from "./Toast";

function ReturnModal({ show, setShow, modalTitle, returnedProducts }) {
  console.log("RETURNED PRODUCTS ARE", returnedProducts);
  const { orderId } = useParams();
  const [quantities, setQuantities] = React.useState(
    returnedProducts.map((product) => ({
      id: product.id,
      quantity: product.sold_quantity,
    }))
  );

  React.useEffect(() => {
    setQuantities(
      returnedProducts.map((product) => ({
        id: product.id,
        quantity: product.sold_quantity,
      }))
    );
  }, [returnedProducts]);

  const handleQuantityChange = (id, newQuantity) => {
    setQuantities(quantities.map((item) => (item.id === id ? { ...item, quantity: newQuantity } : item)));
  };

  const handleIncrement = (id) => {
    const product = returnedProducts.find((product) => product.id === id);
    const currentQuantity = quantities.find((item) => item.id === id).quantity;
    if (currentQuantity < product.sold_quantity) {
      handleQuantityChange(id, currentQuantity + 1);
    }
  };

  const handleDecrement = (id) => {
    const currentQuantity = quantities.find((item) => item.id === id).quantity;
    if (currentQuantity > 1) {
      handleQuantityChange(id, currentQuantity - 1);
    }
  };

  const handleSubmit = async () => {
    const returnRequest = {
      order_id: Number(orderId),
      products: quantities,
    };
    try {
      await axios.post(`https://bluepointwear.com/backend/ar/api/submit/order`, returnRequest, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      myToast("تم الاسترجاع بنجاح");
      setShow(false);
    } catch (err) {
      console.log(err);
    }
  };
  console.log("QQUANTITIES ARE", quantities);
  return (
    <>
      <Modal show={show} size="lg">
        <div className="flex-between modal-header">
          <p className="modal-header-items">{modalTitle}</p>
          <p onClick={() => setShow(false)} className="pointer modal-header-items times">
            ×
          </p>
        </div>
        <div className="container mt-5">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>المنتج</th>
                <th>اللون</th>
                <th>المقاس</th>
                <th>الكميه المسترجعه</th>
              </tr>
            </thead>
            <tbody>
              {returnedProducts.map((product) => (
                <tr key={product.id}>
                  <td>{product.product.title || "No Title"}</td> {/* Use the product's title */}
                  <td>{product.color.translations.find((t) => t.locale === "en").name}</td>
                  <td>{product.size.translations.find((t) => t.locale === "en").name}</td>
                  <td>
                    <div className="quantity-counter" style={{ display: "flex" }}>
                      <span style={{ width: "40px" }} onClick={() => handleDecrement(product.id)} className="minus">
                        {" "}
                        -{" "}
                      </span>
                      <span style={{ width: "40px" }}>{quantities.find((q) => q.id === product.id)?.quantity}</span>
                      <span style={{ width: "40px" }} onClick={() => handleIncrement(product.id)} className="plus">
                        {" "}
                        +{" "}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button onClick={handleSubmit} className="login-button m-2 exchange-button">
          تآكيد الاسترجاع
        </button>
      </Modal>
    </>
  );
}

export default ReturnModal;
