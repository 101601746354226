import {
    GET_USERS, CHANGE_EMAIL, GET_USER_BY_ID, DELETE_USER, CHANGE_PASSWORD, PASSWORD_CHANGE_FAIL,
    CHANGE_MOBILE_FAIL, CHANGE_MOBILE, CHANGE_MOBILE_CONFIRM, CHANGE_MOBILE_CONFIRM_FAIL, CHANGE_EMAIL_FAIL, CONFIRM_EMAIL, CONFIRM_EMAIL_FAIL
} from '../actions/types'

const initialState = {
    users: [],
    user: [],
    updated: [],
    loading: true,
    passwordChange: false,
    changePasswordError: '',
    changeMobileError: '',
    mobileChanged: false,
    mobileConfirmError: '',
    mobileConfirmed: false
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                users: action.payload,
                loading: false
            };
        case GET_USER_BY_ID:
            return {
                ...state,
                user: action.payload?.user,
                loading: false
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                passwowrdChange: true,
                loading: false
            };
        case PASSWORD_CHANGE_FAIL:
            return {
                ...state,
                changePasswordError: action.payload?.response?.status === 403 ? "كلمة السر غير صحيحة" : "تأكيد كلمة السر غير صحيح",
                loading: false
            };
        case CHANGE_MOBILE:
            return {
                ...state,
                mobileChanged: true,
                loading: false
            };
        case CHANGE_EMAIL:
            return {
                ...state,
                emailChanged: true,
                loading: false
            };
        case CHANGE_EMAIL_FAIL:
            return {
                ...state,
                changeEmailError: "البريد الإلكتروني غير صالح",
                loading: false
            };
        case CHANGE_MOBILE_FAIL:
            return {
                ...state,
                changeMobileError: "رقم الهاتف غير صالح",
                loading: false
            };
        case CHANGE_MOBILE_CONFIRM:
            return {
                ...state,
                mobileConfirmed: true,
                loading: false
            };
        case CHANGE_MOBILE_CONFIRM_FAIL:
            return {
                ...state,
                mobileConfirmError: action.payload?.response?.data?.errors,
                loading: false
            };
        case CONFIRM_EMAIL:
            return {
                ...state,
                emailConfirmed: true,
                loading: false
            };
        case CONFIRM_EMAIL_FAIL:
            return {
                ...state,
                emailConfirmError: console.log(action.payload?.response),
                loading: false
            };
        case DELETE_USER:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        default:
            return state
    }

}

export default usersReducer;