import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from 'axios'

function ConfirmationModal({ show, setShow, theAction, id, content, title, logout }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);


    const handleFunction = () => {
        dispatch(theAction(id))
        setShow(false)
    }

    const logoutFunc = () => {
        localStorage.clear();
        axios.post(`https://bluepointwear.com/backend/ar/api/logout`, {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        history.push('/')
        window.location.reload();
    }

    // console.log(id)
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <h2 className="text-center mt-5 mb-3">{content}</h2>
                <div className="flex-between p-2">
                    <button onClick={logout ? logoutFunc : handleFunction} className="login-button" variant="primary">
                        تأكيد
                    </button>
                    <button className="login-button red-butt" variant="secondary" onClick={handleClose}>
                        إلغاء
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default ConfirmationModal